import React, {Component} from 'react';
import ClaimDamage from "./claim_damage";
import ClaimItem from "./claim_item";
import PropTypes from "prop-types";


export default class ClaimDetail extends Component {
    render() {
        let claim = this.props.claim;

        return (
            <div className="claim-details">
                {this.props.header ? this.props.header : <h2>Claim {claim.name}</h2>}
                {this.props.show_item && <ClaimItem claim={claim} expanded={this.props.expanded}/> }
                {this.props.show_damage && <ClaimDamage claim={claim} expanded={this.props.expanded}/> }
            </div>
        )
    }
}

ClaimDetail.propTypes = {
    claim: PropTypes.object.isRequired,
    show_item: PropTypes.bool,
    show_damage: PropTypes.bool,
    header: PropTypes.any,
};

ClaimDetail.defaultProps = {
    show_item: true,
    show_damage: true,
    header: null,
};
