import React from 'react';

import Fa from "alpaca.js/dist/util/fa";
import ModalButton from "../util/modalButton";
import ApiActionButton from "../util/apiActionButton";
import {BootstrapTextArea} from "alpaca.js/dist/autoform/bootstrap";
import ApiForm from "../forms/apiForm";
import LoadingButton from "alpaca.js/dist/util/loadingButton";
import {validateRequired} from "../forms/inputValidators";
import ApiReactSelect from "alpaca.js/dist/api/apiReactSelect";
import {Button} from "reactstrap";

const AcceptModalBody = ({workOrder, onComplete, toggleModal}) => {
    return (
        <>
            <p>Please contact the customer to schedule their appointment. Thank you.</p>

            <div className="text-center mt-5">
                <Button color="danger" outline onClick={toggleModal} className="mr-3">Cancel</Button>
                <ApiActionButton color="primary" url={`/api/work-orders/${workOrder.id}/accept/`} onComplete={onComplete}>
                    Confirm Accept
                </ApiActionButton>
            </div>
        </>
    );

};


const DeclineModalBody = ({workOrder, onComplete, toggleModal}) => {
    return (
        <ApiForm url={`/api/work-orders/${workOrder.id}/decline/`} style={{marginTop: -10}} onComplete={onComplete}>{({loading}) =>
            <>
                <ApiReactSelect
                    url={'/api/work-order-decline-reasons/'}
                    field="decline_reason"
                    label="Reason"
                    validate={validateRequired} />

                <BootstrapTextArea field="notes" label="Notes" validate={validateRequired} />

                <div className="text-center mt-5">
                    <Button color="danger" outline onClick={toggleModal} className="mr-3" type="button">Cancel</Button>
                    <LoadingButton color="primary" loading={loading}>Confirm Decline</LoadingButton>
                </div>
            </>
        }</ApiForm>
    );
};

export default function AcceptDeclineButtons({workOrder, onComplete}) {
    if(!(workOrder.status === 'pending' && workOrder.sub_status === null)) {
        return null;
    }

    return (
        <div>
            <ModalButton
                color="primary"
                size="lg"
                className="mr-3"
                modalTitle="Agree to Terms"
                modalBody={({toggleModal}) => <AcceptModalBody workOrder={workOrder} onComplete={onComplete} toggleModal={toggleModal} />}>
                <Fa icon="thumbs-up" /> Accept
            </ModalButton>

            <ModalButton
                color="primary"
                size="lg"
                modalTitle="Reason for Declining Work Order"
                modalBody={({toggleModal}) => <DeclineModalBody workOrder={workOrder} onComplete={onComplete} toggleModal={toggleModal} />}>
                <Fa icon="thumbs-down" /> Decline
            </ModalButton>
        </div>
    )
}
