import React, {useState} from 'react';

import {Button, Modal, ModalBody, ModalFooter, ModalHeader} from "reactstrap";


export default function ModalButton({modalTitle, modalBody, modalFooter, children, getToggle, ...rest}) {
    const [modalOpen, setModalOpen] = useState(false);
    let toggleModal = () => setModalOpen(!modalOpen);

    if(getToggle) {
        getToggle(toggleModal);
    }

    return (
        <>
            <Modal isOpen={modalOpen} toggle={toggleModal}>
                <ModalHeader tag="h4" toggle={toggleModal}>{modalTitle}</ModalHeader>
                {modalBody &&
                    <ModalBody>{_.isFunction(modalBody) ? modalBody({toggleModal: toggleModal}) : modalBody}</ModalBody>
                }
                {modalFooter &&
                    <ModalFooter>{modalFooter}</ModalFooter>
                }
            </Modal>

            <Button {...rest} onClick={toggleModal} type="button">
                {children}
            </Button>
        </>
    );
}
