import React, {Component, Fragment} from 'react';
import {Row, Col} from "reactstrap";
import {Form, Scope, useFormState, Text} from "informed";
import axios from 'axios';
import Fa from "alpaca.js/dist/util/fa";
import {
    BootstrapReactSelect,
    BootstrapTextArea
} from "alpaca.js/dist/autoform/bootstrap";
import DamageServiceList from "./claimDamageServiceList";
import DamageCodeSelectorField from "./damageCodeSelectorField";
import {MultiAttachmentField} from "../autoform/multiAttachmentInput";
import {crmClient} from "../crmapi";
import {validateRequired} from "../forms/inputValidators";
import LoadingButton from "alpaca.js/dist/util/loadingButton";
// import {withAPIData} from "alpaca.js/dist/api/withAPIData";
import {addFormErrors} from "alpaca.js/dist/autoform/util";
import ApiReactSelect from "alpaca.js/dist/api/apiReactSelect";
import {withAPIData} from "../withAPIData";


const YES_NO_OPTIONS = [
    { value: '1', label: 'Yes' },
    { value: '0', label: 'No' },
];

const WORK_STATUS_OPTIONS = [
    { value: 'successful', label: 'Successful' },
    { value: 'unsuccessful', label: 'Unsuccessful' },
    { value: 'partially_successful', label: 'Partially Successful' },
    { value: 'returned_an_estimate', label: 'Returned an Estimate' },
    { value: 'additional_part_labor_needed', label: 'Additional Part/Labor Needed' },
    { value: 'customer_missed_appointment', label: 'Customer Missed Appointment' },
];

const CUSTOMER_SATISFIED_OPTIONS = [
    { value: 'satisfied', label: 'Satisfied' },
    { value: 'dissatisfied', label: 'Dissatisfied' },
];

const REPAIR_OPTIONS = [
    { value: 'complete', label: 'Complete' },
    { value: 'incomplete', label: 'Incomplete' },
];

const REPAIR_OPTIONS_ALL = _.concat(REPAIR_OPTIONS, [
    { value: 'additional_part_labor_needed', label: 'Additional Part/Labor Needed' },
]);

const REPAIR_OPTIONS_MAP = {
    'partially_successful': REPAIR_OPTIONS,
    'additional_part_labor_needed': REPAIR_OPTIONS_ALL,
};

const SHOULD_HIDE_PROMPTS_FOR_STATUSES = [
    'customer_missed_appointment',
    'returned_an_estimate',
];

class WorkOrderCompletionBase extends Component {
    constructor(props) {
        super(props);
        this.submit = this.submit.bind(this);

        this.state = {
            submitting: false,
            invoicing: false,
        };
    }

    canSubmit() {
        if(this.props.loading || this.state.submitting) {
            return false;
        }

        return true;
    }

    getApi(api) {
        this.formApi = api;
    }

    submit(data) {
        if(!this.canSubmit()) {
            return;
        }

        this.setState({submitting: true});

        let wo_id = this.props.id;
        let component = this;

        axios.post(`/api/work-orders/${wo_id}/complete/`, data).then((response) => {
            if(component.state.invoicing) {
                window.location.pathname = `/work-orders/${wo_id}/invoice/`;
            } else {
                window.location.pathname = `/work-orders/${wo_id}/`;
            }
        }).catch(function(error) {
            component.setState({submitting: false});

            if(error.response === undefined) {
                console.log(error);
            } else if(error.response.status === 400 && component.formApi !== null) {
                addFormErrors(error.response.data, component.formApi);
            }
        });
    }

    submitAndInvoice() {
        this.setState({invoicing: true}, this.formApi.submitForm);
    }

    submitWithoutInvoice() {
        this.setState({invoicing: false}, this.formApi.submitForm);
    }


    renderDamage(claim_index, damage_index, service, damage) {

        const formState = useFormState();
        let damagePath = `claims[${claim_index}].damages[${damage_index}]`;
        let damage_different = parseInt(_.get(formState.values, `${damagePath}.damage_different`, 0)) === 1;
        let service_different = parseInt(_.get(formState.values, `${damagePath}.service_different`, 0)) === 1;
        let work_order_status = _.get(formState.values, 'sub_status');

        let should_prompt = !_.includes(SHOULD_HIDE_PROMPTS_FOR_STATUSES, work_order_status);

        let repair_choices = _.get(REPAIR_OPTIONS_MAP, work_order_status, []);
        let repair_enabled = repair_choices.length > 0;

        if(!repair_enabled) {
            repair_choices = _.filter(WORK_STATUS_OPTIONS, (x) => x.value === work_order_status);
        }

        return <>
            <Text field="id" type="hidden" initialValue={damage.id} />

            <Row>
                <Col>
                    <BootstrapReactSelect
                        key={`repair_status_${claim_index}_${damage_index}_${work_order_status}`}
                        field="repair_status"
                        label="Repair Status"
                        options={repair_choices}
                        isDisabled={!repair_enabled}
                        value={repair_enabled ? undefined : repair_choices[0]}
                        validate={repair_enabled ? validateRequired : undefined}
                        validateOnChange />
                </Col>

                <Col>
                    {should_prompt &&
                        <BootstrapReactSelect
                            field="damage_different"
                            label="Damage Different than Reported?"
                            options={YES_NO_OPTIONS}
                            validate={validateRequired}
                            validateOnChange/>
                    }
                </Col>

                <Col>
                    {should_prompt &&
                        <BootstrapReactSelect
                            field="service_different"
                            label="Service Different than Requested?"
                            options={YES_NO_OPTIONS}
                            validate={validateRequired}
                            validateOnChange/>
                    }
                </Col>
            </Row>

            {should_prompt && <>
                {service_different && <>
                    <h5>Actual Service Performed</h5>
                    <ApiReactSelect
                        initialValue={{value: service.uuid, label: service.name}}
                        url={'/api/vendors-services/'}
                        field="actual_service"
                        paginate={true}
                        showLabel={false}
                        valueField="uuid"
                        validate={service_different ? validateRequired : undefined}
                        validateOnChange />
                </>}

                {damage_different && <>
                    <h5>Damage Differences</h5>
                    <DamageCodeSelectorField
                        field="damage_code"
                        code_tree={service.damage_code_tree}
                        initialValue={damage.code_actual || damage.code_reported}
                        validateOnChange
                        showLabel={false}
                        onChange={() => {this.formApi.setError(`${damagePath}.damage_code`, undefined)}} />
                </>}
            </>}
        </>
    }

    renderResolutionClaim(resolution_claim, rc_index) {
        let claim_id = resolution_claim.examination_claim.claim.id;
        let claim_index = _.findIndex(this.props.data.claims, c => c.id === claim_id);
        let claim = this.props.data.claims[claim_index];

        return (
            <Fragment key={`rc_${rc_index}`}>
                <h3 className="mb-3">Claim {claim.name}</h3>

                <Scope scope={`claims[${rc_index}]`}>

                    <Text field="id" type="hidden" initialValue={claim_id} />

                    <DamageServiceList
                        workOrder={this.props.data}
                        claim={claim}
                        resolutionClaim={resolution_claim}>{({service, index, damage}) => (
                        <Scope scope={`damages[${index}]`}>
                            {this.renderDamage(rc_index, index, service, damage)}
                            <hr className="mb-4 mt-4" />
                        </Scope>
                    )}</DamageServiceList>

                </Scope>

            </Fragment>

        );
    }

    shouldPromptCustomerSatisfied(formState) {
        let work_order = this.props.data;
        let all_inspection = true;

        for(let service_index in work_order.services) {
            if(!work_order.services[service_index].is_inspection) {
                all_inspection = false;
                break;
            }
        }

        return !all_inspection && !_.includes(SHOULD_HIDE_PROMPTS_FOR_STATUSES, _.get(formState.values, 'sub_status'));
    }

    renderForm() {
        let work_order = this.props.data;
        let canSubmit = this.canSubmit();

        return <Form onSubmit={this.submit} getApi={this.getApi.bind(this)}>{({formState, formApi}) => (
            <>
                {/*<pre><code>{JSON.stringify(formState, null, 4)}</code></pre>*/}

                <BootstrapReactSelect
                    field="sub_status"
                    label="Work Order Status"
                    options={WORK_STATUS_OPTIONS}
                    validate={validateRequired}
                    validateOnChange />

                {this.shouldPromptCustomerSatisfied(formState) &&
                    <BootstrapReactSelect
                        field="customer_satisfied"
                        label="Customer Satisfaction"
                        options={CUSTOMER_SATISFIED_OPTIONS}
                        validate={validateRequired}
                        validateOnChange/>
                }

                {work_order.resolution_claims.map((resolution_claim, index) =>
                     this.renderResolutionClaim(resolution_claim, index)
                )}

                <BootstrapTextArea field="notes" label="Notes" rows={3} validate={validateRequired} />

                <div className="form-group">
                    <label>Upload work documentation images</label>

                    <MultiAttachmentField
                        field="attachments"
                        axiosInstance={crmClient}
                        wrapperClass="work-order-attachments"
                        deleteIcon="minus-circle"
                        validateOnChange />
                </div>

                <div className="mt-5 text-center">
                    <LoadingButton color="primary" type="button" className="mr-3" size="lg" disabled={!canSubmit} onClick={this.submitWithoutInvoice.bind(this)} loading={this.state.submitting && !this.state.invoicing}>
                        Complete Work Order
                    </LoadingButton>

                    {/*TODO: hook up invoicing*/}
                    <LoadingButton color="primary" type="button" outline size="lg" disabled={!canSubmit} onClick={this.submitAndInvoice.bind(this)} loading={this.state.submitting && this.state.invoicing}>
                        Complete Work Order and Invoice
                    </LoadingButton>
                </div>
            </>
        )}</Form>;
    }

    renderWorkOrderCompletion() {
        return (
            <div className="container">
                <Row className="justify-content-center">
                    <Col xl={10}>
                        <div className="panel pt-5 pb-5">
                            <Row className="justify-content-center">
                                <Col xl={10}>
                                    {this.renderForm()}
                                </Col>
                            </Row>
                        </div>
                    </Col>
                </Row>
            </div>
        )
    }

    render() {
        return !this.props.loading ? (
            this.renderWorkOrderCompletion()
        ) : (
            <div className="p-4 text-center">
                <Fa icon="spinner" spin size={1} />
            </div>
        );
    }
}

const WorkOrderCompletion = withAPIData(WorkOrderCompletionBase, '/api/work-orders/${id}/');
export default WorkOrderCompletion;
