import React, {Component, Fragment} from 'react';
import { Row, Col } from "reactstrap";
import axios from 'axios';
import ClaimDetail from "../claims/claim_detail";
import {get_first_letter} from 'alpaca.js/dist/util/util';
import {SidePanel} from "../util/side_panel";
import Fa from "alpaca.js/dist/util/fa";
import PlainTextParagraph from "../util/plainTextParagraph";
import WorkOrderStatusBanner, {StatusAndDate} from "./statusBanner";
import AcceptDeclineButtons from "./acceptDeclineButtons";
import AttemptsToContactBanner from "./attemptsToContactBanner";
import DamageServiceList from "./claimDamageServiceList";
import WorkSummaryCard from "./workSummary";
import CancelButton from "./cancelButton";


function phoneData(contact) {
    if(contact.phones.length === 0) {
        return null;
    }

    return <Fragment>
        {_.map(contact.phones, (phone, index) =>
            <Fragment key={`phone_${phone.id}`}>
                <span className="d-flex justify-content-between">
                    <span>{phone.phone}</span>
                    <span>
                        {phone.primary && <Fa icon="product-hunt" prefix="fab" className="text-larger text-primary"/>}
                        &#160;&#160;
                        {phone.type && <i className="text-muted">{_.capitalize(phone.type)}</i>}
                    </span>
                </span>
                {index < contact.phones.length - 1 && <hr/>}
            </Fragment>
        )}
    </Fragment>;
}

function emailData(contact) {
    if(contact.emails.length === 0) {
        return null;
    }

    return <Fragment>
        {_.map(contact.emails, (email, index) =>
            <Fragment key={`email_${email.id}`}>
                <span className="d-flex justify-content-between">
                    <span><a href={`mailto:${email.email}`}>{email.email}</a></span>
                    <span>
                        {email.primary && <Fa icon="product-hunt" prefix="fab" className="text-larger text-primary"/>}
                        &#160;&#160;
                        {email.type && <i className="text-muted">{_.capitalize(email.type)}</i>}
                    </span>
                </span>
                {index < contact.emails.length - 1 && <hr/>}
            </Fragment>
        )}
    </Fragment>;
}

function sidePanelData(work_order) {
    let claim = work_order.claims[0];
    let contact = null;
    let address = null;

    if(claim) {
        contact = claim.contact;

        if(contact) {
            address = contact.addresses[0];
        }
    }

    return [
        [
            {label: "Ticket", value: `#${work_order.claims[0].ticket}`, inline: true},
            {
                label: "Request Status",
                value: <span className={`status-${work_order.sub_status}`}>
                    <StatusAndDate workOrder={work_order} sub_status={work_order.sub_status}/>
                </span>
            },
            {
                label: "Customer Name",
                value: <span>
                    <span className="avatar avatar-blank mr-2">{get_first_letter(work_order.customer_name || 'U')}</span>
                    {work_order.customer_name || '[Unknown]'}
                </span>
            },
            {
                label: "Customer Address",
                value: address ? address.full_address : null
            },
            {
                label: "Customer Phone",
                value: phoneData(contact)
            },
            {
                label: "Customer Email",
                value: emailData(contact)
            },
        ],
    ];
}

export default class WorkOrder extends Component {
    constructor(props) {
        super(props);

        this.state = {
            loading: true,
            work_order: null,
            errors: {}
        };
    }

    componentDidMount() {
        let component = this;

        axios.get(`/api/work-orders/${component.props.id}/`).then((response) => {
            component.setState({work_order: response.data, loading: false});
        });
    }

    renderResolutionClaim(resolution_claim, rc_index) {
        let claim_id = resolution_claim.examination_claim.claim.id;
        let claim_index = _.findIndex(this.state.work_order.claims, c => c.id === claim_id);
        let claim = this.state.work_order.claims[claim_index];

        return (
            <Fragment key={`rc_${rc_index}`}>
                <ClaimDetail claim={claim} header={
                    <div>
                        <h2 className="d-inline-block mb-3">Claim {claim.name}</h2>

                        <DamageServiceList workOrder={this.state.work_order} claim={claim} resolutionClaim={resolution_claim} />
                    </div>
                } />
            </Fragment>

        );
    }

    renderWorkOrder() {
        let work_order = this.state.work_order;

        return (
            <>
                <div className="d-flex justify-content-between">
                    <h1>Work Order</h1>

                    <AcceptDeclineButtons
                        workOrder={work_order}
                        onComplete={(data) => {this.setState({work_order: data})}} />

                    <CancelButton
                        workOrder={work_order}
                        onComplete={(data) => {this.setState({work_order: data})}} />
                </div>

                <hr/>

                <Row className="mt-4">
                    <Col md="3" sm="12" >
                        <SidePanel data={sidePanelData(work_order)} back_url="/work-orders/requests/" />
                    </Col>

                    <Col md="9" sm="12">
                        <WorkOrderStatusBanner
                            mode={this.props.mode}
                            workOrder={work_order}
                            onChange={(work_order) => {this.setState({work_order: work_order})}} />

                        {this.props.mode === "schedule" &&
                            <AttemptsToContactBanner
                                workOrder={work_order}
                                onChange={(work_order) => {
                                    this.setState({work_order: work_order})
                                }}/>
                        }

                        <WorkSummaryCard workOrder={work_order} />

                        <h2>Notes</h2>
                        <PlainTextParagraph text={work_order.notes}/>

                        <div className="mb-4">
                            {work_order.resolution_claims.map((resolution_claim, index) =>
                                this.renderResolutionClaim(resolution_claim, index)
                            )}
                        </div>
                    </Col>
                </Row>
            </>
        )
    }

    render() {

        /*jshint ignore:start*/
        return !this.state.loading ? (
            this.renderWorkOrder()
        ) : (
            <Fragment>
                <h1>Work Order</h1>
                <hr/>
                <div className="p-4 text-center">
                    <Fa icon="spinner" spin size={1} />
                </div>
            </Fragment>
        );
        /*jshint ignore:end*/
    }
}
