import React, {Component, Fragment} from 'react';
import { Row, Col, Button } from "reactstrap";
import axios from 'axios';
import {ArrayField, Form, Scope, Text} from 'informed';
import {AddressRow, FloatingSuccessNotification} from "../util/formUtils";
import Fa from "alpaca.js/dist/util/fa";
import {BootstrapReactSelect} from "alpaca.js/dist/autoform/bootstrap";
import {validateRequired} from "../forms/inputValidators";
import LoadingButton from "alpaca.js/dist/util/loadingButton";


export default class VendorLocationsForm extends Component{
    constructor(props){
        super(props);
        this.submitSignup = this.submitSignup.bind(this);
        this.renderLocationForm = this.renderLocationForm.bind(this);
        this.clearSuccessMsg = this.clearSuccessMsg.bind(this);
        this.vendorOptions = this.props.vendors.map((vendor) => ({value: vendor.id, label: vendor.name}));

        this.state = {
            successMsg: undefined,
            loading: false,
        }
    }

    setFormApi(formApi) {
        this.formApi = formApi;
    }

    submitSignup(data) {
        let component = this;
        this.setState({loading: true});

        let locations = data.locations;
        let location_ids = _.map(locations, (l) => l.id);

        for(let index in this.props.initialValues) {
            let existing = this.props.initialValues[index];
            if(!_.includes(location_ids, existing.id)) {
                existing.active = "0";
                locations.push(existing);
            }
        }

        axios.post(`/api/vendors-locations/`, locations).then((response) => {
            this.setState({loading: false});

            if (component.props.next_url !== window.location.pathname) {
                window.location.pathname = this.props.next_url;
            } else {
                component.setState({successMsg: "Successfully Updated Locations"});
            }
        }).catch((error) => {
            this.setState({loading: false});
            component.formApi.setFormError(error.response.data);
            component.clearSuccessMsg();
        });
    }

    clearSuccessMsg(event){
        this.state.successMsg && this.setState({successMsg: undefined});
    }

    renderLocationForm(delete_function, initialValues){
        return (
            <Row>
                <Col sm={8}>
                    <AddressRow address_label=" " scope={null}/>
                </Col>

                <Col sm={3}>
                    <BootstrapReactSelect
                        field="vendor"
                        showLabel={false}
                        options={this.vendorOptions}
                        validate={validateRequired} />
                </Col>

                <Col>
                    <Text field="type" initialValue="physical" type="hidden" />
                    <Text field="active" initialValue="1" type="hidden" />

                     <button type="button" className="btn btn-danger btn-sm no-text" onClick={delete_function}>
                         <i className="fas fa-times" aria-hidden="true" />
                     </button>
                </Col>
            </Row>
        );
    }

    render(){
        let {initialValues}  = this.props;
        let {successMsg} = this.state;

        return(
            <div className="container vendor-info-form">
                <Form
                    initialValues={{locations: initialValues}}
                    onSubmit={this.submitSignup}
                    getApi={this.setFormApi.bind(this)}
                    onSubmitFailure={this.clearSuccessMsg}>
                    {( form ) => (
                        <Fragment>
                            {/*<pre>{ JSON.stringify(form.formState.values) }</pre>*/}
                            {/*<pre>{ JSON.stringify(form.formState.errors) }</pre>*/}

                            <FloatingSuccessNotification successMsg={successMsg} clearSuccessMsg={this.clearSuccessMsg}/>

                            <Row>
                                <Col sm={8}>
                                    <Row>
                                        <Col sm={5}><h6>Address Line</h6></Col>
                                        <Col sm={3}><h6>City</h6></Col>
                                        <Col sm={2}><h6>State</h6></Col>
                                        <Col sm={2}><h6>Zip</h6></Col>
                                    </Row>
                                </Col>
                                <Col sm={4}><h6>Vendor</h6></Col>
                            </Row>

                            <ArrayField field="locations">
                                {({ add, fields }) => (
                                    <>
                                        {fields.map(({ field, key, remove, initialValue }, i) => (
                                            <Scope scope={field} key={key}>
                                                {this.renderLocationForm(remove)}
                                            </Scope>
                                        ))}

                                        <Button onClick={add} className="btn-outline-primary btn-wide-2">
                                            <Fa icon="plus"/> Add Location
                                        </Button>
                                    </>
                                )}
                            </ArrayField>

                            <div className="text-center">
                                <LoadingButton color="primary" className="mt-4" loading={this.state.loading}>
                                    { window.location.pathname === this.props.next_url ? (
                                        <Fragment>Save</Fragment>
                                    ) : (
                                       <Fragment>Next <Fa icon="arrow-right" className="pl-3"/>&#160;</Fragment>
                                    )}
                                </LoadingButton>
                            </div>

                            {!_.isEmpty(form.formState.errors) &&
                                <Row className="mt-4">
                                    <Col className="text-center">
                                        <p className="invalid-feedback text-center auto-width ">
                                            There are errors and the form cannot be submitted yet. Check fields for error messages to fix the issues.
                                        </p>
                                    </Col>
                                </Row>
                            }
                        </Fragment>
                    )}
                </Form>
            </div>
        )
    }
}
