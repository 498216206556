import React, {Component} from 'react';
import axios from 'axios';
import {Form } from 'informed';
import LocationServicesForm from "./locationServicesForm";
import {FloatingSuccessNotification} from "../util/formUtils";
import Fa from "alpaca.js/dist/util/fa";
import LoadingButton from "alpaca.js/dist/util/loadingButton";


export default class ServicesAndZipsForm extends Component{
    constructor(props){
        super(props);
        this.submitForm = this.submitForm.bind(this);
        this.clearSuccessMsg = this.clearSuccessMsg.bind(this);
        this.state = {
            loading: false,
        };
    }

    clearSuccessMsg(event){
        this.state.successMsg && this.setState({successMsg: undefined, loading: false});
    }

    submitForm(data) {
        this.setState({loading: true});

        let newData = [];

        for(let index in data) {
            let locationData = _.clone(data[index]);
            let servicePrices = [];

            for (let service_line in locationData.service_prices) {
                let service_data = locationData.service_prices[service_line];

                if(!service_data.offered) {
                    // Don't include if it's not offered
                    continue;
                }

                // set the location id back on the service
                service_data.location = locationData.id;

                servicePrices.push(service_data);
            }

            locationData.service_prices = servicePrices;

            newData.push(locationData);
        }

        let component = this;

        // submit form
        axios.patch(`/api/vendors-locations/`, newData).then((response) => {
            if(component.props.next_url !== window.location.pathname) {
                window.location.pathname = this.props.next_url;
            } else {
                component.setState({successMsg: "Successfully Updated Locations", loading: false});
            }
        }).catch((error) => {
            let form = this.formApi.getFormState();
            form.errors = error.response.data;
            this.formApi.setFormState(form);
            component.clearSuccessMsg();
        });
    }

    setFormApi(formApi) {
        this.formApi = formApi;
    }

    render(){
        let {successMsg} = this.state;
        let data = this.props.data;

        return(
            <div className="container vendor-info-form">
                <Form initialValues={data} onSubmit={this.submitForm} getApi={this.setFormApi.bind(this)}>
                    {( form ) => (
                        <>
                            {/*<pre>{ JSON.stringify(form.formState.values) }</pre>*/}
                            {/*<pre>{ JSON.stringify(this.state.locationZips) }</pre>*/}
                            {/*<pre>{ JSON.stringify(form.formState.errors) }</pre>*/}

                            <FloatingSuccessNotification successMsg={successMsg} clearSuccessMsg={this.clearSuccessMsg}/>

                            {data && data.map((location, index) =>
                                <LocationServicesForm
                                    key={index}
                                    location={location}
                                    index={index}
                                    form={form}
                                    services={this.props.services}
                                />
                            )}

                            <div className="text-center">
                                <LoadingButton color="primary" className="mt-4" type="submit" loading={this.state.loading}>
                                    {window.location.pathname === this.props.next_url ? (
                                        "Save"
                                    ) : (
                                       <>Next <Fa icon="times" className="pl-3"/>&#160;</>
                                    )}
                                </LoadingButton>
                            </div>

                            {!_.isEmpty(form.formState.errors) &&
                                <div className="text-center">
                                    <p className="invalid-feedback text-center auto-width">
                                        There are errors and the form cannot be submitted yet. Check fields for error messages to fix the issues.
                                    </p>
                                </div>
                            }
                        </>
                    )}
                </Form>
            </div>
        )
    }
}
