import React, {Component, Fragment} from 'react';
import { Row, Col, Button, Table } from "reactstrap";
import axios from 'axios';
import FieldInput from "../forms/field_input";
import linkState from 'alpaca.js/dist/util/link_state';
import ClaimDetail from "../claims/claim_detail";
import LabeledValue from "../claims/labeled_value";
import {currency, get_first_letter} from 'alpaca.js/dist/util/util';
import {SidePanelWrapper} from "../util/side_panel";
// import {withAPIData} from "alpaca.js/dist/api/withAPIData";
import LoadingWrapper from "alpaca.js/dist/util/loadingWrapper";
import {withAPIData} from "../withAPIData";
import {VendorInteractionsPanel} from "../interactions/vendor_interactions_panel";


const LineItemsTable = ({invoice, children}) => {
    return (
        <>
            <h4>Line Items</h4>

            <Table className="mb-1">
                <thead>
                    <tr>
                        <th>Description</th>
                        <th>Amount Requested</th>
                        <th>Amount Paid</th>
                    </tr>
                </thead>
                <tbody>
                    {children}
                </tbody>
                <tfoot>
                    <tr>
                        <th>Totals</th>
                        <th>{currency(invoice.requested_amount)}</th>
                        <th>{currency(invoice.approved_amount)}</th>
                    </tr>
                </tfoot>
            </Table>
        </>
    );
};

function sidePanelData(data) {
    let plan = data.claims[0].plan;
    let customer_name = data.claims[0].contact.name;

    return [
        [
            {label: "Ticket", value: `#${data.claims[0].ticket}`},
            {
                label: "Invoice Status",
                value: <span className={`status-${data.status}`}>{_.startCase(data.status_display)} {data.status_date && moment(data.status_date).format('MM/DD/YYYY')}</span>
            },
        ],
        [
            {
                label: "Customer Name",
                value: <span>
                    <span className="avatar avatar-blank mr-2">{get_first_letter(customer_name || 'U')}</span>
                    {customer_name || '[Unknown]'}
                </span>
            },
            {label: "Plan", value: plan.plan_number},
        ],
    ];
}

class InvoiceDetailBase extends Component {
    constructor(props) {
        super(props);
        // this.submit = this.submit.bind(this);

        this.state = {
            errors: {}
        };
    }

    canSubmit() {
        let invoice = this.props.data;
        let loading = this.props.loading;

        // retail and wholesale price for every quote request claim must be provided
        // any available parts must have time frame and price fields
        if (loading || this.inReview()) {
            return false;
        }

        return (
            (_.trim(invoice.requested_amount) !== "") &&
            (_.trim(invoice.invoice_number) !== ""));
    }

    inReview() {
        return (!loading && invoice.date_submitted !== null);
    }

    // submit() {
    //     let invoice_id = this.props.id;
    //     let invoice = this.state.invoice;
    //
    //     let request_data = {
    //         'invoice_number': invoice.invoice_number,
    //         'requested_amount': invoice.requested_amount
    //     };
    //
    //     // console.log("Submit Request Data", request_data);
    //
    //     axios.patch(`/api/invoices/${invoice_id}/`, request_data).then((response) => {
    //         window.location.pathname = $('.back-link').attr('href');
    //     });
    // }

    // renderRequestForm() {
    //     let invoice = this.state.invoice;
    //
    //     return (
    //         <form>
    //             <Row className="mt-4">
    //                 <LabeledValue sm={6} md={3} label="AUTH Number" value={invoice.examination_resolution_id} />
    //                 <LabeledValue sm={6} md={3} label="Authorized Amount" value={currency(invoice.authorized_amount)} />
    //             </Row>
    //             <Row>
    //                 <Col sm={6} md={3}>
    //                     <FieldInput
    //                         label="Retailer Invoice Number"
    //                         value={invoice.invoice_number}
    //                         onChange={linkState(this, "invoice", ["invoice_number"])}
    //                         readOnly={this.inReview()}
    //                     />
    //                 </Col>
    //                 <Col sm={6} md={3}>
    //                     <FieldInput
    //                         label="Requested Amount"
    //                         value={invoice.requested_amount}
    //                         onChange={linkState(this, "invoice", ["requested_amount"])}
    //                         readOnly={this.inReview()}
    //                         type="number"
    //                     />
    //                 </Col>
    //             </Row>
    //
    //             {!this.inReview() &&
    //                 <Button color="primary" className="mt-4" onClick={this.submit} disabled={!this.canSubmit()}>
    //                     Submit
    //                 </Button>
    //             }
    //         </form>
    //     );
    // }

    renderLineItems(items) {
        return (
            <LineItemsTable invoice={this.props.data}>
                {items.map((item, index) =>
                    <tr key={`inv_line_${index}`}>
                        <td>{item.description}</td>
                        <td>{currency(item.requested_amount)}</td>
                        <td>{currency(item.approved_amount)}</td>
                    </tr>
                )}
            </LineItemsTable>
        );
    }

    render() {
        let invoice = this.props.data;

        return (
            <LoadingWrapper loading={this.props.loading}>{() => (
                <SidePanelWrapper data={sidePanelData(invoice)} back_url="/invoices/open/">
                    <div className="mb-3">
                        {invoice.claims.map((claim, index) =>
                            <ClaimDetail key={"claim_" + index} claim={claim} show_item={false}/>
                        )}
                    </div>

                    <div className="row mb-3">
                        {invoice.portal_notes && (
                            <div className="col-6">
                                <h4>My Notes</h4>
                                <p>{invoice.portal_notes}</p>
                            </div>
                        )}

                        {invoice.crm_notes && (
                            <div className="col-6">
                                <h4>Guardian's Notes</h4>
                                <p>{invoice.crm_notes}</p>
                            </div>
                        )}
                    </div>

                    {this.renderLineItems(invoice.line_items)}
                    {/*{this.renderRequestForm()}*/}

                    <VendorInteractionsPanel ticket_id={invoice.claims[0].ticket} vendor_uuid={this.props.vendor_uuid} />
                </SidePanelWrapper>
            )}</LoadingWrapper>

        );
    }
}

const InvoiceDetail = withAPIData(InvoiceDetailBase, '/api/invoices/${id}/');
export default InvoiceDetail;
