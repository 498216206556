import React, {Component} from 'react';
import {Col, Row} from "reactstrap"
import ClaimDamage from "./claim_damage";
import ClaimItem from "./claim_item";


export default class ClaimDetailExtra extends Component {
    render() {
        let claim = this.props.claim;
        return (
            <div className="claim-details">
                <h2>Claim {claim.name}</h2>
                <Row>
                    <Col sm={4}>
                        <strong>Status:</strong> {claim.status}
                    </Col>
                    <Col sm={4}>
                        <strong>Plan:</strong> <a href={this.props.plan_link} target="blank">{claim.plan.plan_number}</a>
                    </Col>
                </Row>
                <ClaimItem claim={claim} expanded={this.props.expanded}/>
                <ClaimDamage claim={claim} expanded={this.props.expanded}/>
            </div>
        )
    }
}

