import React, {Fragment} from "react";
import classNames from 'classnames';

const PlainTextParagraph = ({text}) => {
    let paragraphs = _.split(text, /(\s*\n\s*){2,}/);

    return (
        <Fragment>
            {_.map(paragraphs, (p, i) => (
                <p key={`p${i}`}>{p}</p>
            ))}
        </Fragment>
    )
};

export default PlainTextParagraph;
