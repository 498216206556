
export const validateRequired = value => {
  return value === undefined || _.trim(value) === "" ? 'This field is required.' : undefined;
}

export const validateNumber = value => {
    return value && isNaN(value) ? 'Field must be a valid number': undefined;
}

export const validatePhone = value => {
    if (value) {
        value = value.replace(/[-+ ()]/g, '');
        return isNaN(value) || value.length !== 10 && value.length !== 11 ? 'Field must be a valid phone number.' : undefined;
    } else {
        return undefined;
    }
}

export const validateSSN= value => {
    if (value) {
        value = value.replace(/[- ]/g, '');
        return isNaN(value) || value.length !== 9 ? 'Field must be a valid social security number.' : undefined;
    } else {
        return undefined;
    }
}
export const validatePercent= value => {
    if (value) {
        let numericVal = parseFloat(value)
        return 0 > numericVal || numericVal > 100? 'Field must be a valid number (0-100).': undefined;
    } else {
        return undefined;
    }
}

export const validateRequiredPhone = value => {
    let requiredValidation = validateRequired(value);
    if (requiredValidation){ return requiredValidation; }
    return validatePhone(value);
}

export const validateRequiredZip = value => {
    let requiredValidation = validateRequired(value);
    if (requiredValidation){ return requiredValidation; }
    return isNaN(value) || value.length !== 5 ? 'Field must be a valid 5 digit zip.' : undefined;
}

export const validateRequiredEmail = value => {
    let requiredValidation = validateRequired(value);
    if (requiredValidation){ return requiredValidation; }
    return!(
        /^[a-zA-Z0-9.!#$%&’*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/.test(value)
    ) ? 'Field must be a valid email address': undefined;
}

export const validateMailingAddress = (value, values) => {
    let field_is_empty = validateRequired(value);
    return !values.mailing_equal_physical && field_is_empty ? 'This field is required if mailing address is not the physical address': undefined;
}

export const validateMailingAddressZip = (value, values) => {
    let errMsg = undefined;
    if (values.mailing_equal_physical){     // we don't care about the value so don't spend time validating it
        return errMsg;
    }
    // validate the the value is there && is a valid zip
    let isEmpty = validateRequired(value);
    if (isEmpty){ return 'This field is required if mailing address is not the physical address'; }
    return isNaN(value) || value.length !== 5 ? 'Field must be a valid 5 digit zip.' : undefined;
}

export const validatePasswordStrength = (value) => {
    let requiredValidation = validateRequired(value);
    if (requiredValidation){ return requiredValidation; }
    return value.length < 8 ? "This password is too short. It must contain at least 8 characters." : undefined;
}

export const validatePasswordConfirmation = (value, values) => {
    let requiredValidation = validateRequired(value);
    if (requiredValidation){ return requiredValidation; }
    return value !== values.user.password1 ? 'Passwords do not match': undefined;
}
