import React from 'react';
import PropTypes from 'prop-types';
import Select2 from '../forms/select2';
import classNames from 'classnames';
import { FormGroup } from 'reactstrap';


export default class DamageCodeSelector extends React.Component {
    constructor(props) {
        super(props);
        this.handleChange = this.handleChange.bind(this);

        let selected = null;

        if(!_.isNil(this.props.value) && this.props.value.length > 0) {
            selected = this.props.value[0];
        }

        this.state = {
             selected: selected,
        };
    }

    handleChange(event) {
        this.setState(
            {selected: parseInt(event.target.value)},
            function() {
                const { onChange } = this.props;

                if (onChange) {
                    if(this.state.selected) {
                        onChange([this.state.selected]);
                    } else {
                        onChange(null);
                    }

                }
            }
        );
    }

    handleSubChange(code) {
        const { onChange } = this.props;

        if (onChange) {
            if(code) {
                onChange([this.state.selected].concat(code));
            } else {
                onChange([this.state.selected]);
            }
        }
    }

    handleDescriptionChange(event) {
        const { onChange } = this.props;

        if (onChange) {
            onChange([this.state.selected].concat([event.target.value]));
        }
    }

    renderSubCode() {
        if(this.state.selected === null) {
            return null;
        }

        let value = [];
        if(!_.isNil(this.props.value) && this.props.value.length > 0) {
            value = this.props.value.slice(1, this.props.value.length);
        }

        let description = "";
        if(value.length > 0) {
            description = value[0];
        }

        for(let index in this.props.code_tree) {
            let code_tree = this.props.code_tree[index];
            if(code_tree.id === this.state.selected) {
                if(code_tree.children !== undefined){
                    return <DamageCodeSelector
                        code_tree={code_tree.children}
                        level={this.props.level + 1}
                        onChange={this.handleSubChange.bind(this)}
                        value={value}
                        error={this.props.error} />
                } else if (code_tree.requires_description) {
                    return <div className="sub-field">
                        <FormGroup className={classNames({"is-invalid": this.props.error})}>
                            <label>Damage Description</label>
                            <input onChange={this.handleDescriptionChange.bind(this)} value={description} className={classNames("form-control", {"is-invalid": this.props.error})}/>
                        </FormGroup>
                    </div>
                }
            }
        }

        return null;
    }

    render() {
        let label = 'Damage Code';
        if (this.props.level > 0){
            label = 'Subcode ' + this.props.level;
        }

        return (
            <div className={classNames({'sub-field': this.props.level > 0})}>
                <FormGroup className={classNames({"is-invalid": this.props.error})}>
                    <label>{label}</label>
                    <Select2 onSelect={this.handleChange} data={this.props.code_tree} value={this.state.selected} emptyLabel="--------"/>
                    {this.renderSubCode()}

                    {this.props.error && this.props.level === 0 &&
                        <span className="invalid-feedback">{this.props.error}</span>
                    }
                </FormGroup>
            </div>
        );
    }
}

DamageCodeSelector.propTypes = {
    level: PropTypes.number,
};

DamageCodeSelector.defaultProps = {
    level: 0,
};
