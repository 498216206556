import React, {Fragment} from "react";
import classNames from 'classnames';
import ScheduleAppointmentBanner from "./scheduleAppointment";
import {CountDownText} from "../util/countdown";
import moment from "moment";

export const StatusAndDate = ({workOrder, status=null, sub_status=null, label=null}) => {
    let dates_field = "status_dates";

    if(sub_status) {
        dates_field = "sub_status_dates";
        status = sub_status;
    }

    let date = _.get(workOrder, `${dates_field}.${status}`);

    if(!label) {
        label = _.startCase(status);
    }

    if(date) {
        date = moment(date);
        label = `${label} ${date.format('M/D/YY, h:mma')}`
    }

    return label;
};

const WorkOrderOpenRequestBanner = ({workOrder}) => {
    let due_date = workOrder.date_request_response_due;

    if(due_date === null) {
        return null;
    }

    return (
        <CountDownText date={due_date}>{({days, remaining_text}) => (
            <div className={classNames("alert", days > 1 ? "alert-warning" : "alert-danger", "small")}>
                Open request, {remaining_text}
            </div>
        )}</CountDownText>
    )
};


const WorkOrderAcceptedBanner = ({workOrder}) => {

    return (
        <div className={classNames("alert", "alert-success", "small", "d-flex justify-content-between")}>
            <span className="text-large">
                <StatusAndDate workOrder={workOrder} sub_status="accepted" label="Accepted" />
                <small><a href={`/work-orders/schedule/${workOrder.id}/`} className="ml-5" style={{textDecoration: "underline"}}>Work Order #{workOrder.id}</a></small>
            </span>
        </div>
    )
};


const WorkOrderDeclineBanner = ({workOrder}) => {
    return (
        <div className={classNames("alert", "alert-danger", "small", "d-flex justify-content-between")}>
            <span className="text-large">
                <StatusAndDate workOrder={workOrder} sub_status="declined" label="Declined" />
            </span>
        </div>
    )
};


const WorkOrderClosedBanner = ({workOrder}) => {
    return (
        <div className={classNames("alert", "alert-success", "small", "d-flex justify-content-between")}>
            <StatusAndDate workOrder={workOrder} status="fulfilled" label="Closed" />
        </div>
    )
};

const WorkOrderStatusBanner = ({workOrder, onChange, mode}) => {
    if(workOrder.status === 'pending' && workOrder.sub_status === null) {
        return <WorkOrderOpenRequestBanner workOrder={workOrder} />
    } else if(workOrder.status === 'pending' && workOrder.sub_status === "accepted" && mode === "request") {
        return <WorkOrderAcceptedBanner workOrder={workOrder}/>
    } else if(workOrder.status === 'closed' && workOrder.sub_status === "declined") {
        return <WorkOrderDeclineBanner workOrder={workOrder}/>
    } else if (workOrder.status === 'pending' && _.includes(['accepted', 'scheduled', 'rescheduled'], workOrder.sub_status)) {
        return <ScheduleAppointmentBanner workOrder={workOrder} onChange={onChange} />
    } else if (workOrder.status === 'fulfilled') {
        return <WorkOrderClosedBanner workOrder={workOrder} onChange={onChange} />
    }

    return null;
};

export default WorkOrderStatusBanner;
