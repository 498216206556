import React from 'react';

import Fa from "alpaca.js/dist/util/fa";
import ModalButton from "../util/modalButton";
import {BootstrapTextArea} from "alpaca.js/dist/autoform/bootstrap";
import ApiForm from "../forms/apiForm";
import LoadingButton from "alpaca.js/dist/util/loadingButton";
import {validateRequired} from "../forms/inputValidators";
import ApiReactSelect from "alpaca.js/dist/api/apiReactSelect";
import {Button} from "reactstrap";


const CancelModalBody = ({workOrder, onComplete, toggleModal}) => {
    return (
        <ApiForm url={`/api/work-orders/${workOrder.id}/cancel/`} style={{marginTop: -10}} onComplete={onComplete}>{({loading}) =>
            <>
                <ApiReactSelect
                    url={'/api/work-order-cancel-reasons/'}
                    field="cancel_reason"
                    label="Reason"
                    validate={validateRequired} />

                <BootstrapTextArea field="notes" label="Notes" validate={validateRequired} />

                <div className="text-center mt-5">
                    <Button color="danger" outline onClick={toggleModal} className="mr-3" type="button">Cancel</Button>
                    <LoadingButton color="primary" loading={loading}>Submit</LoadingButton>
                </div>
            </>
        }</ApiForm>
    );

};

export default function CancelButton({workOrder, onComplete}) {
    if(!(workOrder.status === 'pending' && _.includes(["accepted", "scheduled", "rescheduled"], workOrder.sub_status))) {
        return null;
    }

    return (
        <div className="pt-3">
            <ModalButton
                color="danger"
                outline
                size="xs"
                modalTitle="Reason for Canceling Work Order"
                modalBody={({toggleModal}) => <CancelModalBody workOrder={workOrder} onComplete={onComplete} toggleModal={toggleModal} />}>
                <Fa icon="times" /> Cancel Work Order
            </ModalButton>
        </div>
    )
}
