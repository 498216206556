import DamageCodeList from "../claims/damageCodeList";
import {currency} from "alpaca.js/dist/util/util";


function DamageService({service, index, claim, children}) {
    let damage = _.find(claim.damages, (damage) => damage.id === service.damage_id);

    let trip_charge = parseFloat(service.trip_charge);

    return (
        <div>
            <div className="d-flex justify-content-between mb-3">
                <div>
                    <h4 className="d-inline-block mt-2">Damage #{index+1}</h4>
                    <DamageCodeList damage_names={damage.code_actual_names} className={'d-inline-block ml-4'}/>
                </div>

                <div className="alert alert-success d-inline-block ml-4 mb-0">
                    <strong className="text-large">Repair {currency(service.price)}</strong>
                    {trip_charge > 0 &&
                        <small>&#160;+<strong>{currency(trip_charge)}</strong> trip fee</small>
                    }
                    <span className="small ml-3">Service: {service.name}</span>
                </div>
            </div>

            {!_.isNil(children) && (
                _.isFunction(children) ? children({service: service, damage: damage, index: index}) : children
            )}
        </div>
    )
}

export default function DamageServiceList({claim, resolutionClaim, workOrder, children}) {
    let services = _.filter(workOrder.services, (x) => x.examination_claim_id === resolutionClaim.examination_claim.id);

    return <>
        {_.map(services, (service, index) => (
            <DamageService
                service={service}
                key={`service${index}`}
                index={index}
                claim={claim}
                children={children} />
        ))}
    </>;
}
