import React, {Component} from 'react';

import {Alert, Col, Row} from "reactstrap";
import { Form} from "informed";
import {BootstrapDateInput, BootstrapTextArea} from "alpaca.js/dist/autoform/bootstrap";
import {validateRequired} from "../forms/inputValidators";
import moment from "moment";
import ModalButton from "../util/modalButton";
import LoadingButton from "alpaca.js/dist/util/loadingButton";
import axios from "axios";
import {CountDownText} from "../util/countdown";
import ApiReactSelect from "alpaca.js/dist/api/apiReactSelect";


export default class ScheduleAppointmentBanner extends Component {
    constructor(props) {
        super(props);

        this.state = {
            loading: false,
        };
    }

    onSubmit(reschedule, data) {
        this.setState({loading: true});

        let workOrder = this.props.workOrder;
        let date = moment(`${data.date} ${data.time}`, "MM/DD/YYYY h:mmA");

        let newData = _.omit(data, ['date', 'time']);
        newData.appointment_date = date.format();

        let component = this;

        axios.post(`/api/work-orders/${workOrder.id}/schedule_appointment/`, newData).then((response) => {
            component.setState({loading: false});

            component.props.onChange(response.data);

            if(component.modalToggle && reschedule) {
                component.modalToggle();
            }
        });
    }

    renderScheduleAppt() {
        let workOrder = this.props.workOrder;

        if (!(workOrder.status === 'pending' && _.includes(['accepted', 'scheduled', 'rescheduled'], workOrder.sub_status))) {
            return null;
        }

        let due_date = workOrder.date_schedule_due;

        let today = new Date();
        today.setHours(0,0,0,0);

        return (
            <div style={{background: '#fff3cd', padding: 20, borderRadius: 5}} className="mt-3 mb-3">
                <div>
                    <h5 className="d-inline-block mr-4">Schedule Appointment</h5>
                    {due_date != null &&
                        <small className="text-muted"><CountDownText date={due_date} /></small>
                    }
                </div>

                <Form onSubmit={this.onSubmit.bind(this, false)}>
                    <Row>
                        <Col lg="3" md="5">
                            <BootstrapDateInput field="date" label="Date" options={{minDate: today}} validate={validateRequired}/>
                        </Col>
                        <Col lg="3" md="5">
                            <BootstrapDateInput field="time" label="Time" icon="clock" dateFormat="h:mmA" options={{stepping: 15}} validate={validateRequired} />
                        </Col>
                        <Col lg="4" md="6">
                            <div className="control-label d-sm-none d-xs-none d-lg-block">&#160;</div>
                            <LoadingButton color="warning" size="sm" type="submit" loading={this.state.loading}>Schedule Appointment</LoadingButton>
                        </Col>
                    </Row>

                    <p style={{fontWeight: "normal", color: "#444444"}}>
                        Please contact the customer directly to schedule your appointment details before confirming here.
                    </p>

                </Form>
            </div>
        )
    }

    renderModalBody() {
        return (
            <div style={{maxWidth: 375, margin: "auto", marginTop: -20, marginBottom: 20}}>
                <Form onSubmit={this.onSubmit.bind(this, true)}>
                    <ApiReactSelect
                        url={'/api/work-order-reschedule-reasons/'}
                        field="reason"
                        label="Why do you need to reschedule?"
                        validate={validateRequired} />
                    <BootstrapDateInput field="date" label="New appointment date" options={{minDate: new Date()}} validate={validateRequired}/>
                    <BootstrapDateInput field="time" label="New appointment time" icon="clock" dateFormat="h:mmA" options={{stepping: 15}} validate={validateRequired} />
                    <BootstrapTextArea field="notes" label="Notes" validate={validateRequired} />

                    <LoadingButton color="primary" type="submit" block loading={this.state.loading}>Reschedule Appointment</LoadingButton>
                </Form>
            </div>
        );
    }

    renderScheduledBanner() {
        let workOrder = this.props.workOrder;

        let date = moment(workOrder.appointment_date);

        return (
            <Alert color="info" className="d-flex justify-content-between">
                <small className="mt-1"><strong>Appointment date {date.format('M/D/YY, h:mma')}</strong></small>

                <div>
                    <ModalButton
                        size="xs"
                        color="info"
                        outline
                        modalTitle="Reschedule This Appointment"
                        modalBody={this.renderModalBody()}
                        getToggle={(toggle) => {this.modalToggle=toggle}}
                    >
                        Reschedule Appointment
                    </ModalButton>
                    &#160;&#160;
                    <a className="btn btn-info btn-xs" href={`/work-orders/${workOrder.id}/complete/`}>Complete Work Order</a>
                </div>
            </Alert>
        )

    }

    render() {
        let workOrder = this.props.workOrder;

        if (workOrder.status === 'pending' && workOrder.sub_status === 'accepted') {
            return this.renderScheduleAppt();
        } else if (workOrder.status === 'pending' && _.includes(['scheduled', 'rescheduled'], workOrder.sub_status)) {
            return this.renderScheduledBanner();
        }

        return null;
    }
}
