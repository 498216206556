import React from 'react';
import {Row} from "reactstrap";
import LabeledValue from "./labeled_value";
import CollapsedCard from "./collapsed_card";
import {currency} from "alpaca.js/dist/util/util";


const ItemPanel = (props) => {
    // console.log('props in item panel', props);
    return (
        <div className="claim-detail-section">
            <Row>
                <LabeledValue size="3" label="Item" value={props.description}/>
                <LabeledValue size="3" label="Type" value={props.item_type}/>
                <LabeledValue size="3" label="Material" value={props.item_material}/>
                <LabeledValue size="3" label="Retail Amount" value={currency(props.retail_price)}/>
            </Row>

            <Row>
                <LabeledValue size="3" label="Retail SKU" value={props.retail_sku}/>
                <LabeledValue size="3" label="Serial #" value={props.serial_number}/>
                <LabeledValue size="3" label="Invoice #" value={props.invoice_number}/>
            </Row>
        </div>
    );
};


export default class ClaimItem extends React.Component {
    render() {
        // console.log('claim item props', this.props);
        return (
            <CollapsedCard header="Item Details" expanded={this.props.expanded}>
                <ItemPanel {...this.props.claim.item} invoice_number={this.props.claim.plan.invoice_number} />
            </CollapsedCard>
        );
    }
}
