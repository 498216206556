import React, {Component, Fragment} from 'react';
import {Row} from "reactstrap";
import LabeledValue from "./labeled_value";
import CollapsedCard from "./collapsed_card";
import {AttachmentViewer} from "../attachment_viewer";
import {crmClient} from "../crmapi";
import moment from "moment";


const DamageLocation = (props) => {
    return (
        <Row>
            <LabeledValue label="Damage Location" value={props.location.name}/>
            <LabeledValue label="Damage Size" value={props.size}/>
            <LabeledValue label="Damage Quantity" value={props.quantity}/>
        </Row>
    );
};


class DamagePanel extends Component {

    renderDamageCodeList() {
        if (this.props.code_actual_names) {
            return (
                <Fragment>
                    {this.props.code_actual_names.map((name, index) =>
                        <span key={`damages_${index}`}>{name}</span>
                    )}
                </Fragment>
            );
        }
    }

    render() {
        let detail = this.props.detail_reported;

        let damage_date = detail.damage_date == null ? 'Unknown' : moment(detail.damage_date).format('MM/DD/YYYY');
        let damage_code = this.renderDamageCodeList();

        return (
            <div className="claim-detail-section">
                <Row>
                    <LabeledValue label="Cause of Damage" value={detail.cause}/>
                    <LabeledValue label="Type of Cause" value={detail.cause_type.name}/>
                    <LabeledValue label="Date of Damage" value={damage_date}/>
                </Row>

                {detail.locations.map((location, index) =>
                    <DamageLocation {...location} key={`dl_${location.id}_${index}`}/>
                )}

                <Row>
                    <LabeledValue label="Actual Damage Code(s)" value={damage_code} size="12" valueClass="damage-code-list"/>
                </Row>
            </div>
        );
    }
}



export default class ClaimDamage extends React.Component {
    render() {
        if (this.props.claim === undefined || this.props.claim.damages === undefined) {
            return null;
        }
        return (
            <CollapsedCard header="Damage Details" expanded={this.props.expanded}>
                {this.props.claim.damages.map((damage, index) =>
                    <DamagePanel {...damage} key={`dp_${damage.id}_${index}`}/>
                )}
                <Fragment>
                    <h5>Review Documentation</h5>

                    { this.props.claim.attachment_data.length ?
                        <AttachmentViewer
                            attachments={this.props.claim.attachment_data}
                            baseUrl={this.props.claim.url}
                            axiosInstance={crmClient} />
                    :
                        <i className="text-muted"> No documentation</i>
                    }
                </Fragment>
            </CollapsedCard>
        );
    }
}
