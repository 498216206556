import {Form} from "informed";
import {Component} from "react";
import PropTypes from "prop-types";
import axios from "axios";
import {addFormErrors} from "alpaca.js/dist/autoform/util";

export default class ApiForm extends Component {
    constructor(props) {
        super(props);

        this.state = {
            loading: false,
        };

        this.formApi = null;
    }

    getApi(formApi) {
        this.formApi = formApi;
    }

    onSubmit(data) {
        if(this.state.loading) {
            return;
        }

        let {url, axiosInstance, onComplete} = this.props;

        this.setState({loading: true});

        let component = this;

        axiosInstance.post(url, data).then((response) => {
            if(onComplete) {
                onComplete(response.data);
            }
        }).catch(function(error) {
            component.setState({loading: false});

            if(error.response === undefined) {
                console.log(error);
            } else if(error.response.status === 400 && component.formApi !== null) {
                addFormErrors(error.response.data, component.formApi);
            }
        });
    }

    render() {
        let {url, axiosInstance, onComplete, children, ...rest} = this.props;

        return (
            <Form {...rest} getApi={this.getApi.bind(this)} onSubmit={this.onSubmit.bind(this)}>{({...rest}) =>
                _.isFunction(children) ? children({...rest, loading: this.state.loading}) : children
            }</Form>
        );
    }
}

ApiForm.propTypes = {
    axiosInstance: PropTypes.func,
    onComplete: PropTypes.func,
    url: PropTypes.string.isRequired,
};

ApiForm.defaultProps = {
    axiosInstance: axios,
};
