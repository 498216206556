import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import moment from 'moment';
import { getUrlFileName, get_first_letter, lineBreaksHtml } from 'alpaca.js/dist/util/util';
import AttachmentLink from "./attachmentLink";
import Fa from "alpaca.js/dist/util/fa";


export const MESSAGE_TYPES = {
    note: {
        name: "Note",
        icon: "icon-note",
    },
    email: {
        name: "Email",
        icon: "icon-email",
    },
    phone: {
        name: "Call",
        icon: "icon-call",
    },
    sms: {
        name: "SMS",
        icon: "icon-sms",
    },
    system: {
        name: "System Message",
        icon: "icon-system",
    }
};


export default class InteractionRow extends React.Component {

    render() {
        let message = this.props.message;

        return (
            <tr key={message.id}>
                {MESSAGE_TYPES[message.message_type] ? (
                    <td className="type">
                        <i className={classNames("icon", MESSAGE_TYPES[message.message_type].icon)} /><br/>
                        <strong>{MESSAGE_TYPES[message.message_type].name}</strong>
                    </td>
                ) : (
                     <td className="type" />
                )
                }
                <td className="sender">
                    {message.from_object &&
                        <div>
                            {message.from_object.avatar_image ? (
                                <img className="avatar" src={message.from_object.avatar_image} alt={`Profile image for ${message.from_object.name}`} />
                            ) : (
                                <span className="avatar avatar-blank">{get_first_letter(message.from_object.name)}</span>
                            )}
                            &#160;&#160;{message.from_object.name}
                        </div>
                    }
                </td>
                <td className="details" style={{overflow: "visible"}}>
                    {message.message_type === 'phone' && message.attachments.length > 0 ? (
                        <audio src={message.attachments[0].attachment} ref="audio" />
                    ): (
                        <div>
                            <p>{lineBreaksHtml(message.content)}</p>
                            <table className="table-sm no-borders">
                                <tbody>
                                    {message.attachments.map((attachment) =>
                                        <tr key={attachment.id}>
                                            <td>
                                                <AttachmentLink attachment={attachment} messageId={message.id} />
                                            </td>
                                        </tr>
                                    )}
                                </tbody>
                            </table>
                        </div>
                    )}
                    {message.critical &&
                        <span className="label label-danger">
                            <Fa icon="exclamation-triangle"/> Critical
                        </span>
                    }
                </td>
                <td className="date">
                    {moment(message.date_sent).format('MM/DD/YYYY h:mm A zz')}
                </td>
            </tr>
        );
    }
}

InteractionRow.propTypes = {
    message: PropTypes.object.isRequired,
    reload: PropTypes.func.isRequired,
};
