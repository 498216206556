import React from "react";
import classNames from 'classnames';

const DamageCodeList = ({damage_names, ...rest}) => {
    let classes = classNames("damage-code-list", rest.className || "");

    return (
        <div className={classes}>
            {damage_names.map((name, index) => <span key={`damages_${index}`}>{name}</span>)}
        </div>
    )
};

export default DamageCodeList;
