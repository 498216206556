import React, {Fragment} from 'react';
import {Col, Row} from "reactstrap";
import queryString from "query-string";
import classNames from "classnames";


function renderItem(index, item, item_index) {
    let dtClasses = [];
    let ddClasses = [];

    if(item.inline) {
        dtClasses.push("d-inline-block");

        ddClasses.push("d-inline-block");
        ddClasses.push("ml-4");
        ddClasses.push("text-large");
    }

    return (
        <Fragment key={`sp${index}_${item_index}`}>
            <dt className={classNames(dtClasses)}>{item.label}</dt>
            <dd className={classNames(ddClasses)} style={{wordBreak: 'break-word'}}>{item.value ? item.value : <span>&mdash;</span>}</dd>
        </Fragment>
    )
}

export const SidePanel = (props) => {
    /*
        Expects a data prop that is an array of "columns" (one or two)
        Each "column" is an array of dicts, each with "label" and "value" keys
        Each dict can optionally contain "labelClass" and "valueClass"
     */

    /* jshint ignore:start */

    const qs = queryString.parse(window.location.search);
    let back_url = props.back_url;
    if (qs.next !== undefined) {
        back_url = qs.next;
    }

    return (
        <>
            <a href={back_url} className="back-link">Back to Work Orders</a>

            <Row>
                {props.data.map((col_data, index) =>
                    <Col md="12" sm="6" xs="12" key={`sp_col${index}`}>
                        {col_data.map((item, item_index) => renderItem(index, item, item_index))}
                    </Col>
                )}
            </Row>
        </>
    );
};

export const SidePanelWrapper = (props) => {

    return (
        <Row className="mt-4">
            <Col md="3" sm="12" >
                <SidePanel data={props.data} back_url={props.back_url} />
            </Col>
            <Col md="9" sm="12">
                {props.children}
            </Col>
        </Row>
    );
};

