import React, {Component} from 'react';
import { Row, Col } from "reactstrap";
import {BootstrapText} from "alpaca.js/dist/autoform/bootstrap";
import InformedSelect2 from "../forms/InformedSelect2";
import CustomInputRadioGroup from "../forms/customInputRadioGroup";
import {validateRequired} from "../forms/inputValidators";
import { Scope, useFieldState, Text } from 'informed';
import Fa from "alpaca.js/dist/util/fa";
import classNames from 'classnames';



class ServiceLineInner extends Component{
    constructor(props){
        super(props);
        this.validateRequiredIfOffered = this.validateRequiredIfOffered.bind(this);
    }

    validateRequiredIfOffered(value){
        let {offered} = this.props;
        let serviceNotOffered = offered === false;
        let isEmpty = validateRequired(value);
        if (isEmpty && !serviceNotOffered){return "This field is required, since the service is offered.";}
        // if (!isEmpty && serviceNotOffered){return "This field must be blank, since the service is not offered.";}
        return undefined;
    }

    canDelete() {
        return this.props.initialCount && this.props.index < this.props.initialCount;
    }

    render(){
        let {services, delete_function, offered} = this.props;

        return (
            <Row className={classNames({'service-not-offered': offered === false})}>
                <Col sm={4}>
                    <InformedSelect2
                        label=""
                        data={services}
                        field="service"
                        validate={validateRequired}
                        emptyLabel="----------" />
                </Col>

                <Col sm={4}>
                    <CustomInputRadioGroup
                        radioInline={true}
                        field="offered"
                        validate={validateRequired}
                        options={[
                            {value: true, label: "Yes"},
                            {value: false, label: "No"}
                        ]}
                    />
                </Col>

                <Col sm={!this.canDelete()? 4: 4}>
                    <BootstrapText
                        showLabel={false}
                        className="price-field form-control"
                        type="number"
                        field="price"
                        step=".01"
                        validate={this.validateRequiredIfOffered}
                    />

                    <Text type="hidden" field="uuid"/>
                </Col>

                {/*{(!this.canDelete() && delete_function) &&*/}
                {/*    <Col sm={1}>*/}
                {/*        <div className="form-group text-right delete-wrapper">*/}
                {/*             <button type="button" className="btn btn-danger btn-sm no-text" onClick={delete_function}>*/}
                {/*                 <Fa icon="times"/>*/}
                {/*             </button>*/}
                {/*        </div>*/}
                {/*    </Col>*/}
                {/*}*/}
            </Row>
        );
    }
}

const ServiceLine = (props) => {
    let serviceFieldState = useFieldState("service");
    let offeredFieldState = useFieldState("offered");

    return (
        <ServiceLineInner offered={offeredFieldState.value} service={serviceFieldState.value} {...props} />
    );
};

export default ServiceLine;
