import { hot } from 'react-hot-loader/root';

export function register(name, component) {
    window[name] = hot(component);
}

require('./libs');
require('./config');

import { styles } from '../css/style.scss';

import FAQ from './faq/faq';
import Contact from './contact/contact';
import ClaimDetail from './claims/claim_detail';
import ClaimDetailExtra from './claims/claim_detail_extra';
import VendorSignup from './vendors/vendorInfoForm';
import LocationServicesForm from './vendors/vendorLocationsForm';
import ServicesAndZipsForm from './vendors/serviceZipCodeManagement';
import NumberedProgressBar from './util/formUtils';
import WorkOrder from './work_orders/work_orders';
import WorkOrderCompletion from "./work_orders/workOrderCompletion";
import InvoiceDetail from './invoices/invoiceDetail';
import { VendorInteractionsPanel } from "./interactions/vendor_interactions_panel";
import VendorLocationsForm from "./vendors/vendorLocationsForm";
import InvoiceUpload from "./invoices/invoiceUpload";
import InvoiceUploadErrors from "./invoices/invoiceUploadErrors";
import BulkUploadCountButton from "alpaca.js/dist/bulk_upload/bulkUploadCountButton";
import TableCheckboxSelectAllHeader from "alpaca.js/dist/util/tableCheckboxSelectAllHeader";
import TableCheckboxActionButton from "alpaca.js/dist/util/tableCheckboxActionButton";
import ApiBadgeCount from "alpaca.js/dist/util/apiCountBadge";

register('FAQ', FAQ);
register('Contact', Contact);
register('ClaimDetail', ClaimDetail);
register('ClaimDetailExtra', ClaimDetailExtra);
register('VendorSignup', VendorSignup);
register('ServicesAndZipsForm', ServicesAndZipsForm);
register('VendorLocationsForm', VendorLocationsForm);
register('LocationServicesForm', LocationServicesForm);
register('ServicesAndZipsForm', ServicesAndZipsForm);
register('NumberedProgressBar', NumberedProgressBar);
register('WorkOrder', WorkOrder);
register('WorkOrderCompletion', WorkOrderCompletion);
register('InvoiceDetail', InvoiceDetail);
register('VendorInteractionsPanel', VendorInteractionsPanel);
register('TableCheckboxActionButton', TableCheckboxActionButton);
register('InvoiceUpload', InvoiceUpload);
register('InvoiceUploadErrors', InvoiceUploadErrors);
register('BulkUploadCountButton', BulkUploadCountButton);
register('TableCheckboxSelectAllHeader', TableCheckboxSelectAllHeader);
register('ApiBadgeCount', ApiBadgeCount);
