import React, {Component} from 'react';

import LoadingButton from "alpaca.js/dist/util/loadingButton";
import axios from "axios";


export default class ApiActionButton extends Component {
    constructor(props) {
        super(props);
        this.doAction = this.doAction.bind(this);

        this.state = {
            loading: false,
        };
    }

    doAction() {
        this.setState({loading: true});

        let component = this;

        axios.post(this.props.url).then((response) => {
            component.setState({loading: false}, function() {
                if(this.props.onComplete) {
                    this.props.onComplete(response.data);
                }
            });
        });
    }

    render() {
        let {children, url, onComplete, ...rest} = this.props;

        return (
            <LoadingButton {...rest} type="button" loading={this.state.loading} onClick={this.doAction}>
                {children}
            </LoadingButton>
        )
    }
}
