import React, {Component} from 'react';
import { Row, Col, Button } from "reactstrap";
import {Scope, ArrayField, useFieldState, Text} from 'informed';
import {validateRequired} from '../forms/inputValidators';
import {BootstrapText, BootstrapTextArea} from "alpaca.js/dist/autoform/bootstrap";
import ServiceLine from "./serviceLine";
import Fa from "alpaca.js/dist/util/fa";


const ServicesList = ({services, locationIndex}) => {
    return (
        <ArrayField field="service_prices">
            {({ add, fields }) => (
                <>
                    {fields.map(({ field, key, remove, initialValue }, i) => (
                        <Scope scope={field} key={key}>
                            <ServiceLine
                                services={services}
                                field={field}
                                delete_function={remove}
                                locationIndex={locationIndex}
                            />
                        </Scope>
                    ))}

                    {/*<Button onClick={add} className="btn-outline-primary no-text">*/}
                    {/*    <Fa icon="plus"/>*/}
                    {/*</Button>*/}
                </>
            )}
        </ArrayField>
    )
};


class AutoExpandingBootstrapTextArea extends Component {
    componentDidMount() {
        this.refreshHeight({target: this.refs['textarea']})
    }

    refreshHeight(e) {
        e.target.style.height = ""; /* Reset the height*/
        e.target.style.height = Math.min(e.target.scrollHeight, 250) + "px";
    }

    render() {
        return (
            <BootstrapTextArea
                {...this.props}
                ref="textarea"
                style={{resize: "none"}}
                rows={1}
                onInput={this.refreshHeight.bind(this)}
            />
        );
    }
}

const LocationServicesForm = ({services, location, index}) => {
    let validateZipList = (value) => {
        let isEmpty = validateRequired(value);
        if ( isEmpty ) { return isEmpty; }
        let zips = value ? typeof(value) === "string"? value.replace(/ /g, '').split(','): value : [];
        let invalidZips = zips.filter((element) => {return isNaN(element) || element.length !== 5;});
        if (invalidZips.length > 0) {
            return "Must be a comma separated list of valid zip codes";
        } else {
            return undefined;
        }
    };

    return (
        <Scope scope={index}>
            {index > 0 && <hr className="mt-5 mb-5"/>}

            <h3>{location.full_address}</h3>
            <br/>
            <h6>Zip Codes This Location Services</h6>

            <AutoExpandingBootstrapTextArea
                mask={value => value ? typeof(value) === "string"? value.replace(/ /g, '').split(','): value : []}
                showLabel={false}
                field="service_areas.0.service_postal_codes"
                placeholder="00000, 00000"
                helpText="Enter as a comma separated list of zip codes"
                validate={validateZipList}
                validateOnBlur
            />

            <Text type="hidden" field="service_areas.0.uuid" />

            <h6>Trip Charge</h6>

            <Row>
                <Col sm={6}>
                    <BootstrapText
                        showLabel={false}
                        field="trip_charge"
                        type="number"
                        validateOnBlur
                    />
                </Col>
            </Row>

            <Text type="hidden" field="id" />

            <Row>
                <Col sm={4}><h6>Service</h6></Col>
                <Col sm={4}><h6>Offered</h6></Col>
                <Col sm={4}><h6>Price</h6></Col>
            </Row>

            <ServicesList services={services} locationIndex={index}/>
        </Scope>
    );
};

LocationServicesForm.defaultProps = {
    services: [],
    location: {},
    index: 0,
    form: null,
};

export default LocationServicesForm;
