import React, {Component} from 'react';
import {Scope, ArrayField, Text} from 'informed';
import BulkUploadErrorList from "alpaca.js/dist/bulk_upload/bulkUploadErrorList";
import {BootstrapText} from "alpaca.js/dist/autoform/bootstrap";
import {Button} from "reactstrap";
import Fa from "alpaca.js/dist/util/fa";
import {currency} from "alpaca.js/dist/util/util";
import axios from "axios/index";

const COLUMN_MAPPINGS = {
    examination_resolution_id: "Auth Number",
    invoice_number: "Invoice Number",
    requested_amount: "Amount",
    authorized_amount: "Authorized Amount",
    customer_name: "Customer Name",
    portal_notes: "Notes",
};


const LineItemTable = ({fields, add, row}) => {
    // this is a hack until this is fixed: https://github.com/joepuzzo/informed/issues/219
    if(fields.length === 0) {
        for(let x=0; x<row.data.line_items.length; ++x) {
            add();
        }
    }

    let isCustom = (i) => i >= row.data.line_items.length || _.startsWith(row.data.line_items[i], "custom");

    return (
        <>
            {fields.map(({ field, key, remove, initialValue }, i) => (
                <tr key={key}>
                    <td>
                        <BootstrapText
                            field={`${field}.description`}
                            showLabel={false}
                            disabled={!isCustom(i)}
                            error={_.get(row, ["errors", "line_items", i, "requested_amount"])} />

                        <Text type="hidden" field={`${field}.line_key`} />
                        <Text type="hidden" field={`${field}.id`} />
                    </td>
                    <td>
                        <BootstrapText
                            field={`${field}.requested_amount`}
                            showLabel={false}
                            error={_.get(row, ["errors", "line_items", i, "requested_amount"])} />
                    </td>
                    <td>
                        {isCustom(i) &&
                            <button className="btn btn-link text-danger" onClick={remove}>
                                <Fa icon="times" size={1}/>
                            </button>
                        }
                    </td>
                </tr>
            ))}
            <tr>
                <td colSpan={3}>
                    <Button size="sm" onClick={add} color="primary">Add Line Item</Button>
                </td>
            </tr>
        </>
    )
};

export default class InvoiceUploadErrors extends Component {
    render() {
        return  (
            <div className="container">
                <BulkUploadErrorList
                    url="/api/invoices/bulk/"
                    columns={COLUMN_MAPPINGS}
                    onUpload={() => {document.location = '/invoices/'}}
                    subSectionName="Line Items"
                    shouldRenderSubSection={(row) => row.data.line_items !== undefined}
                    readOnlyColumns={["authorized_amount", "customer_name"]}
                    columnFormatters={{authorized_amount: currency}}
                    columnFieldsProps={{portal_notes: {style: {minWidth: 250}}}}
                    axiosInstance={axios}
                    renderSubSection={(row) =>
                        <table className="table">
                            <thead>
                                <tr>
                                    <th>Description</th>
                                    <th>Amount</th>
                                    <th width="75" />
                                </tr>
                            </thead>
                            <tbody>
                                <ArrayField field="line_items">{({ add, fields }) => (
                                    <LineItemTable add={add} fields={fields} row={row} />
                                )}</ArrayField>
                            </tbody>
                        </table>
                    } />
            </div>
        )
    }
}
