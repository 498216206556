import React, {Fragment} from "react";
import classNames from 'classnames';
import {Button, Col, Row} from "reactstrap";
import CollapsedCard from "../claims/collapsed_card";
import {AttachmentViewer} from "../attachment_viewer";
import {crmClient} from "../crmapi";
import LabeledValue from "../claims/labeled_value";
import PlainTextParagraph from "../util/plainTextParagraph";
import moment from "moment";



const WorkSummaryCard = ({workOrder}) => {
    if(workOrder.status !== 'fulfilled') {
        return null;
    }

    let damage_differents = _.map(_.flatten(_.map(workOrder.response_data, (x) => x.damages)), (x) => x.damage_different);
    let damage_different = _.reduce(damage_differents, (sum, n) => sum || n);

    let date = _.get(workOrder, 'status_dates.fulfilled');

    if(date) {
        date = moment(date);
    }

    return (
        <CollapsedCard header="Work Summary" expanded={true}>
            <Row>
                {date &&
                    <Col sm={4}>
                        <Row>
                            <LabeledValue label="Date" sm={6} value={date.format('M/D/YY')} />
                            <LabeledValue label="Time" sm={6} value={date.format('h:mma')} />
                        </Row>
                    </Col>
                }

                <LabeledValue label="Work Status" sm={8}>
                    <span className={`status-${workOrder.sub_status}`}>{_.capitalize(_.lowerCase(workOrder.sub_status))}</span>
                </LabeledValue>
            </Row>
            <Row>
                <LabeledValue label="Customer Satisfaction" sm={4} value={_.capitalize(_.lowerCase(workOrder.customer_satisfied))}/>
                <LabeledValue label="Damage Different than Reported?" sm={4}>
                    {damage_different ? "Yes, more extensive than reported." : "No"}
                </LabeledValue>
            </Row>
            <Row>
                <LabeledValue label="Work Documentation Images" sm={4}>
                    <AttachmentViewer
                        axiosInstance={crmClient}
                        attachments={workOrder.attachments || []}
                        baseUrl={`/work-orders/${workOrder.id}/`}
                        showLabels={false} />
                </LabeledValue>

                <LabeledValue label="Notes" sm={8}>
                    <PlainTextParagraph text={workOrder.comments} />
                </LabeledValue>
            </Row>
        </CollapsedCard>
    )
};

export default WorkSummaryCard;
