import { asField } from 'informed';
import DamageCodeSelector from "./damage_code_selector";
import React from "react";
import {BootstrapFieldWrapper} from "alpaca.js/dist/autoform/bootstrap";


const DamageCodeSelectorField = asField(({ fieldState, fieldApi, ...props }) => {
    const { value } = fieldState;
    const { setValue } = fieldApi;
    const { onChange, onBlur, initialValue, forwardedRef, ...rest } = props;

    return (
        <DamageCodeSelector
            {...rest}
            ref={forwardedRef}
            value={value}
            onChange={v => {
                setValue(v);
                if (onChange) {
                    onChange(v);
                }
            }}
        />
    );
});

export default BootstrapFieldWrapper(DamageCodeSelectorField);
