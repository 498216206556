import React, {Component, Fragment} from 'react';

import Fa from "alpaca.js/dist/util/fa";
import {Button, Col, Row} from "reactstrap";
import {Form} from "informed";
import {BootstrapChoiceSelect, BootstrapDateInput, BootstrapText} from "alpaca.js/dist/autoform/bootstrap";
import {validateRequired} from "../forms/inputValidators";
import axios from "axios";
import moment from "moment";


const CONTACT_TYPES = [
    {id: 'email', name: 'Email'},
    {id: 'phone', name: 'Phone'},
];

export default class AttemptsToContactBanner extends Component {
    constructor(props) {
        super(props);

        this.state = {
            loading: false,
        };
    }

    onSubmit(data) {
        this.setState({loading: true});

        let workOrder = this.props.workOrder;
        let component = this;

        axios.post(`/api/work-orders/${workOrder.id}/add_contact_attempt/`, data).then((response) => {
            component.setState({loading: false});
            component.props.onChange(response.data);
            component.formApi.reset();
        });
    }

    getApi(api) {
        this.formApi = api;
    }

    render() {
        let workOrder = this.props.workOrder;

        if (!(workOrder.status === 'pending' && _.includes(['accepted', 'scheduled', 'rescheduled'], workOrder.sub_status))) {
            return null;
        }

        return (
            <div style={{background: '#f4f1f1', padding: 20, borderRadius: 5}} className="mt-3 mb-3">
                <h5 className="d-inline-block mr-4">Attempts to Contact</h5>
                <Form onSubmit={this.onSubmit.bind(this)} getApi={this.getApi.bind(this)}>
                    <Row>
                        <Col lg="3" md="5">
                            <BootstrapDateInput field="date" label="Date" validate={validateRequired}/>
                        </Col>
                        <Col lg="3" md="5">
                            <BootstrapChoiceSelect field="contact_type" label="Contact Type" validate={validateRequired} choices={CONTACT_TYPES} allowBlank={true} />
                        </Col>
                        <Col lg="5" md="5">
                            <BootstrapText field="note" label="Note" validate={validateRequired} />
                        </Col>
                        <Col lg="1" md="6">
                            <div className="control-label d-sm-none d-xs-none d-lg-block">&#160;</div>
                            <Button color="primary" size="sm" type="submit" disabled={this.state.loading}>
                                {this.state.loading ? (
                                    <Fa icon="spinner" spin/>
                                ) : (
                                    <Fa icon="plus"/>
                                )}
                            </Button>
                        </Col>
                    </Row>

                    {_.map(workOrder.contact_attempts, (attempt, index) =>
                        <Fragment key={`attempt-${index}`}>
                            <Row style={{fontWeight: "normal"}} className="small">
                                <Col lg="3" md="5">
                                    {moment(attempt.date).format('M/D/YY')}
                                </Col>
                                <Col lg="3" md="5">
                                    {_.capitalize(attempt.contact_type)}
                                </Col>
                                <Col lg="5" md="5">
                                    {attempt.note}
                                </Col>
                            </Row>
                            {index !== workOrder.contact_attempts.length - 1 &&
                            <hr className="mt-2 mb-2"/>
                            }
                        </Fragment>
                    )}

                </Form>

            </div>
        )
    }
}
