import Select2 from "./select2";
import {asField} from 'informed';

class InformedSelect2 extends Select2 {
    handleChange(event) {
        const { onSelect } = this.props;
        const { setValue, setTouched } = this.props.fieldApi;

        setValue($(event.target).val());

        if (onSelect) {
            onSelect(event);
        }
    }

    render() {
        let {
            data, value, fieldState, initialValue, emptyLabel, ajax, placeholder, templateResult, templateSelection, minimumResultsForSearch,
            onChange, minimumInputLength, ...rest
        } = this.props;

        let defaultValue = this.props.multiple? ['', ]: '';

        return (
            <div className="form-group">
                {this.props.label &&
                    /*<Label>{this.props.label}</Label>*/
                    <label> {this.props.label} </label>
                }
                <select
                    ref="select"
                    value={fieldState.value || initialValue || defaultValue}
                    onChange={onChange? onChange: null}    // doing it this way to avoid console warnings
                    data-react-select2
                    data-placeholder={this.props.placeholder}
                    {...rest}
                >
                    {this.props.emptyLabel !== null &&
                        <option key='__empty__' value="">{this.props.emptyLabel}</option>
                    }
                    {this.props.data.map((option) =>
                        this.renderOption(option)
                    )}

                </select>

                {fieldState.error ? (
                  <small style={{ color: 'red' }}>{fieldState.error}</small>
                ) : null}
            </div>
        );
    }

}

export default asField(InformedSelect2);
