import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import {asField} from "informed";
import axios from "axios";
import Fa from 'alpaca.js/dist/util/fa'


export class MultiAttachmentInput extends React.Component {
    constructor(props) {
        super(props);
        this.handleSelectFile = this.handleSelectFile.bind(this);

        this.state = {
            loading: false,
            attachments: this.props.attachments,
        };
    }

    handleSelectFile(event) {
        const { onChange, onStartUpload } = this.props;
        let component = this;

        if(onStartUpload) {
            onStartUpload();
        }

        this.setState({loading: true});

        let requests = [];
        for(let file of event.target.files) {
            let formData = new FormData();
            formData.append('attachment', file);
            if (this.props.attachment_type != null) {
                formData.append('attachment_type', this.props.attachment_type);
            }

            requests.push(
              this.props.axiosInstance.post(this.props.apiUrl, formData)
            );
        }

        axios.all(requests).then(axios.spread(function(...responses){
            component.refs.file_input.value = "";
            $(component.refs.file_input).change();

            component.setState({
                loading: false,
                attachments: component.state.attachments.concat(_.map(responses, (x) => x.data))
            }, function () {
                if (onChange) {
                    onChange(component.state.attachments);
                }
            });
        }))
    }

    handleDeleteFile(id, event) {
        event.preventDefault();

        if(this.state.loading) {
            return;
        }

        const { onChange } = this.props;

        this.setState({
            'attachments': _.filter(this.state.attachments, function(x) { return x.id !== id; })
        }, function(){
            if (onChange) {
                onChange(this.state.attachments);
            }
        });
    }

    canUpload() {
        return !this.state.loading && (this.props.max_number < 0 || this.state.attachments.length < this.props.max_number);
    }

    render() {
        let {selectMultiple, wrapperClass, className, button_text} = this.props;

        return (
            <div className={classNames("multi-attachment", wrapperClass)}>
                <div className="file-upload-input">
                    <div className={classNames(className, {"disabled": !this.canUpload()})}>
                        <span>{button_text}</span>
                        <input type="file" ref="file_input" onChange={this.handleSelectFile} disabled={!this.canUpload()} multiple={selectMultiple}/>
                        {this.state.loading ? (
                            <Fa icon="spinner" spin className="ml-2" />
                        ) : (
                            !_.isNil(this.props.icon) && <Fa icon={this.props.icon} className="ml-2" />
                        )}
                    </div>
                </div>

                <div className="multi-attachment-list">
                    {this.props.showAttachments &&
                        this.state.attachments.map((attachment, index) =>
                            <div className="multi-attachment-file" key={index}>
                                <Fa icon="paperclip" />&#160;&#160;
                                {attachment.filename}&#160;&#160;
                                <a href="#" className="multi-attachment-delete text-danger" onClick={this.handleDeleteFile.bind(this, attachment.id)}>
                                    <Fa icon={this.props.deleteIcon} />
                                </a>
                            </div>
                        )
                    }
                </div>
            </div>
        );
    }
}

MultiAttachmentInput.propTypes = {
    onChange: PropTypes.func,
    attachments: PropTypes.array,
    attachment_type: PropTypes.string,
    button_text: PropTypes.string,
    max_number: PropTypes.number,
    onStartUpload: PropTypes.func,
    axiosInstance: PropTypes.func,
    apiUrl: PropTypes.string,
    wrapperClass: PropTypes.string,
    deleteIcon: PropTypes.string,
    selectMultiple: PropTypes.bool,
};

MultiAttachmentInput.defaultProps = {
    attachments: [],
    button_text: "Browse",
    attachment_type: null,
    max_number: -1,
    className: "btn btn-primary btn-block",
    icon: "upload",
    showAttachments: true,
    axiosInstance: axios,
    apiUrl: '/attachments/',
    wrapperClass: null,
    deleteIcon: "times",
    selectMultiple: true,
};

export const MultiAttachmentField = asField(({ fieldState, fieldApi, ...props }) => {
    const { value } = fieldState;
    const { setValue } = fieldApi;
    const { onChange, onBlur, initialValue, forwardedRef, ...rest } = props;

    return (
        <MultiAttachmentInput
            {...rest}
            ref={forwardedRef}
            attachments={value}
            onChange={v => {
                setValue(v);
                if (onChange) {
                    onChange(v);
                }
            }}
        />
    );
});

