import axios from 'axios';
import qs from 'qs';
import {addAxiosErrorHandling} from "alpaca.js/dist/api/axios";

addAxiosErrorHandling(axios, 'sentry_id', '/users/login/');

axios.defaults.paramsSerializer = function(params) {
    return qs.stringify(params, { indices: false });
};

axios.defaults.xsrfHeaderName = "X-CSRFToken";
axios.defaults.xsrfCookieName = "csrftoken";
