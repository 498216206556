import React from 'react';
import { Col } from "reactstrap";


const LabeledValue = (props) => {
    return (
        <Col sm={props.size || props.sm || "4"} md={props.md}>
            <dt>{props.label}</dt>
            <dd className={props.valueClass}>
                {props.value || props.children}&nbsp;
            </dd>
        </Col>
    );
};


export default LabeledValue;
