import React, { Fragment, Component } from 'react';
import {Scope, Option, Text} from 'informed';
import { Row, Col,Label } from "reactstrap";
import { validateRequired, validatePhone, validateMailingAddress, validateMailingAddressZip, validateRequiredZip, validateRequiredPhone} from '../forms/inputValidators';
import {BootstrapText} from "alpaca.js/dist/autoform/bootstrap";
import CustomInputRadioGroup from "../forms/customInputRadioGroup";
import Fa from "alpaca.js/dist/util/fa";
import classNames from "classnames";
import {BootstrapReactSelect} from "alpaca.js/dist/autoform";


const stateAbbreviations = [
 'AL','AK','AS','AZ','AR','CA','CO','CT','DE','DC','FM','FL','GA',
 'GU','HI','ID','IL','IN','IA','KS','KY','LA','ME','MH','MD','MA',
 'MI','MN','MS','MO','MT','NE','NV','NH','NJ','NM','NY','NC','ND',
 'MP','OH','OK','OR','PW','PA','PR','RI','SC','SD','TN','TX','UT',
 'VT','VI','VA','WA','WV','WI','WY'
].map((item)=> { return {value: item, label: item} });

export class AddressRow extends Component {
    constructor(props) {
        super(props);
        this.renderRow = this.renderRow.bind(this);
    }

    renderOption(opt) {
        let id = null;
        let name = null;

        if(opt.constructor === Array){
            id = opt[0];
            name = opt[1];
        } else {
            id = opt.id;
            name = opt.name;
        }

        return <Option key={id} value={id}>{name}</Option>;
    }

    renderRow(){
        let {scope, isMailingAddr, initialValues, showLabels, ...rest} = this.props;
        return (
            <Row>
                <Text
                    type="hidden"
                    field="id"
                    initialValue={initialValues && initialValues.id}
                />

                <Col sm={5}>
                    <BootstrapText
                        showLabel={showLabels}
                        label={this.props.address_label}
                        type="text"
                        field="line1"
                        validate={isMailingAddr? validateMailingAddress: validateRequired}
                        initialValue={initialValues && initialValues.line1}
                    />
                </Col>
                <Col sm={3}>
                    <BootstrapText
                        showLabel={showLabels}
                        label="City"
                        field="city"
                        validate={isMailingAddr? validateMailingAddress: validateRequired}
                        initialValue={initialValues && initialValues.city}
                    />
                </Col>
                <Col sm={2}>
                    <div className="form-group">
                        {showLabels && <Label htmlFor="bootstrap-field-2">State</Label>}

                        <BootstrapReactSelect
                            options={stateAbbreviations}
                            field="state"
                            showLabel={false}
                            emptyLabel="-------"
                            validate={validateRequired}
                            initialValue={initialValues && initialValues.state}
                        />
                    </div>
                </Col>
                <Col className='col-sm-2'>
                    <BootstrapText
                        showLabel={showLabels}
                        label="Zip"
                        field="zip_code"
                        validate={isMailingAddr? validateMailingAddressZip: validateRequiredZip}
                        initialValue={initialValues && initialValues.zip_code}
                    />
                </Col>

             </Row>
        )
    }

    render() {
        let {scope} = this.props;
        return (
            <Fragment>
            {scope ? (
                <Scope scope={scope}>{this.renderRow()}</Scope>
            ) : (
                <Fragment>{this.renderRow()}</Fragment>
            )}
            </Fragment>
        )
    }
}

AddressRow.defaultProps = {
    address_label: "Address",
    scope: "address",
    form: null,
    isMailingAddr: false,   // for custom validation of mailing address
    showLabels: false,
};



// this is a wrapper around an input field that essentially just adds a phone number placeholder and col width
export function BootstrapPhoneText(props){
    let {required, ...rest} = props;
    return (
        <Col sm={6}>
        <BootstrapText {...rest}  validate={required? validateRequiredPhone: validatePhone}/>
        </Col>
    )
}
BootstrapPhoneText.defaultProps = {
    label: "Phone Number",
    field: "phone",
    placeholder: "000-000-0000",
    required: true,
};


export default class NumberedProgressBar extends Component {
    renderSectionIcon(index, currIndex){
        let sectionNum = index + 1;
        let is_first = index === 0;
        let visited = sectionNum <= currIndex;
        return <Fragment key={`section_icon_${sectionNum}`}>
            <div className={(is_first ? "" : "Line-3") + (visited? " visited": "")}/>
            <div className={"section-icon" + (visited? " visited": "")}>{ sectionNum }</div>
        </Fragment>
    }

    render(){
        let {currSection, sectionCount, ...rest} = this.props;
        let sectionNums =  Array.from({length: sectionCount}, (value, key) => key);
        return <div className="text-center">
            <div className="progress-bar-circles">

                { sectionNums.map((index) =>  this.renderSectionIcon(index, currSection)) }
            </div>
        </div>
    }

}

// this class is a wrapper around input fields to style them (as conditionally required)
export class ConditionallyRequiredInput extends Component {
    constructor(props){
        super(props);
        this.renderInput = this.renderInput.bind(this);
        this.validateInput = this.validateInput.bind(this);
    }

    validateInput(value, values){
        let {conditionField, conditionValue, extraValidation} = this.props;
        // if the condition isn't met, we don't care about this input's value
        if (values[conditionField] !== conditionValue){return undefined;}
        let isEmpty = validateRequired(value)
        // return error if condition is met and the value is empty
        if (isEmpty){return "This Field is Required";}
        // perform any extra validation there might be
        if (extraValidation){ return extraValidation(value); }
        // no other errors to check for
        return undefined;
    }

    renderInput(){
        let {type, extraValidation, conditionField, conditionValue, inline, ...rest} = this.props;

        if(inline ) {
            rest.className = "ml-3";
        }

        if (type === 'radio'){
            return <CustomInputRadioGroup {...rest} validate={this.validateInput}/>;
        } else{
            return <BootstrapText type={type} {...rest} validate={this.validateInput}/>;
        }
    }

    render(){
        return (
            <div className={classNames("conditionally-required-input", {"form-inline": this.props.inline})}>
                {this.renderInput()}
            </div>
        )
    }
}

ConditionallyRequiredInput.defaultProps = {
    inline: true,
    type: "text",
    conditionField: null,   // field name of input it is related to
    conditionValue: null,   // field value that indicates this field is required
    extraValidation: null,  // function for extra validation on the field value i.e. validateNumber
};


export const deep_value = (obj, path) => {
    for (var i=0, path=path.split('.'), len=path.length; i<len; i++){
        obj = obj[path[i]];
        if (obj === undefined){ return obj } // so we don't have errors if part of the path doesn't exist
    };
    return obj;
};

export function FloatingSuccessNotification (props) {
    let {successMsg, clearSuccessMsg} = props;
    return (
        <Fragment>
            {successMsg && (
                <div className="success-msg-container">
                    <div className="alert alert-success success-msg" role="alert">
                        <p>{successMsg}</p>
                        <div onClick={clearSuccessMsg}>
                            <Fa icon="times"/>
                        </div>
                    </div>
                </div>
            )}
        </Fragment>
    );
}
