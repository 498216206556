import useCountdown from 'react-use-countdown';
import parseMs from 'parse-ms';
import React from "react";

export const CountDownText = ({date, children}) => {
    const countdown = useCountdown(() => date);
    const { days, hours, minutes, seconds } = parseMs(countdown);

    let remaining_text;

    if(countdown <= 0) {
        remaining_text = 'past due';
    } else if (days > 0) {
        remaining_text = `${days} days remaining`;
    } else if (hours > 0) {
        remaining_text = `${hours} hours remaining`;
    } else {
        remaining_text = `${minutes} minutes remaining`;
    }

    if(children && _.isFunction(children)) {
        return children({days: days, remaining_text: remaining_text});
    } else if(children) {
        return children;
    } else {
        return (
            <>{remaining_text}</>
        );
    }
};
