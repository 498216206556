import React from 'react';
import PropTypes from 'prop-types';
import { Row, Col, Button, FormGroup, Input, Table, UncontrolledTooltip } from 'reactstrap';
import axios from 'axios';
import Paginator from '../util/paginator';
import InteractionRow from './interaction_row';
import Fa from 'alpaca.js/dist/util/fa';
import {crmClient} from "../crmapi";
import FileInput from "alpaca.js/dist/forms/file_input";


export class VendorInteractionsPanel extends React.Component {
    constructor(props) {
        super(props);
        this.loadMessages = this.loadMessages.bind(this);
        this.changePage = this.changePage.bind(this);
        this.changeFiles = this.changeFiles.bind(this);
        this.addMessage = this.addMessage.bind(this);
        this.changeMessage = this.changeMessage.bind(this);
        this.changeCritical = this.changeCritical.bind(this);

        this.state = {
            show_reply: false,
            data: {},
            current_page: 1,
            total_pages: 1,
            messages: [],
            files: [],
            message: "",
            critical: false,
            sending_message: false,
            loading: false,
            error_msg: false,
        };
    }

    componentDidMount() {
        this.loadMessages();
    }

    loadMessages() {
        if(this.source) {
            this.source.cancel();
        }

        this.setState({loading: true});
        this.source = axios.CancelToken.source();

        let params = {
            page: this.state.current_page,
            channel: this.props.channel,
            page_size: 5,
            ticket: this.props.ticket_id,
            vendor_uuid: this.props.vendor_uuid
        };

        let component = this;

        crmClient.get('messages/', {params: params}).then(function(response) {
            component.setState({
                current_page: response.data.current_page,
                messages: response.data.results,
                total_pages: response.data.total_pages,
                loading: false,
            });
        }).catch(function(){
            component.setState({loading:false});
        });
    }

    changeFiles(files) {
        this.setState({files: files});
    }

    changeMessage(event) {
        this.setState({message: event.target.value});
    }

    changeCritical(event) {
        this.setState({critical: event.target.checked});
    }

    addMessage() {
        if(this.state.message.trim().length === 0){
            return;
        }

        this.setState({
            sending_message: true,
        });


        let formData = new FormData();
        formData.append('content', this.state.message);
        formData.append('ticket', this.props.ticket_id);
        formData.append('vendor_uuid', this.props.vendor_uuid);
        formData.append('critical', this.state.critical);

        for(let index in this.state.files) {
            formData.append("files", this.state.files[index]);
        }

        let component = this;

        crmClient.post('messages/', formData).then(function(response) {
            if (response.status === 201) {
                component.setState({
                    message: "",
                    critical: false,
                    files: [],
                    sending_message: false,
                    loading: false,
                    error_msg: null,
                });
                component.loadMessages();
            } else {
                component.setState({
                    error_msg: 'Message failed to send.',
                    sending_message: false,
                });
            }
        }).catch((err) => {
            component.setState({
                error_msg: 'Message failed to send.',
                sending_message: false,
            });
        });
    }

    cancelNoteOrMessage() {
        this.setState({show_reply: false});
    }

    showReply() {
        this.setState({show_reply: true});
    }

    changePage(page) {
        this.setState({current_page: page}, this.loadMessages);
    }

    renderHeader() {
        return (
            <Row className="mb-3">
                <Col>
                    <h3>Interactions</h3>
                </Col>
            </Row>
        )
    }

    renderReply() {
        if(!this.state.show_reply) {
            return (
                <div className="reply-box text-center">

                    {this.props.can_add_email ? (
                        <Button color="primary" className="btn-wide" onClick={this.showReply.bind(this)}>
                            <i className="icon icon-email-white" style={{margin: "-3px 10px -3px 0"}} /> Send Email
                        </Button>
                    ) : (
                        <div className="d-inline-block">
                            <UncontrolledTooltip target="no_email_send_message_tooltip">
                                No email address on file
                            </UncontrolledTooltip>

                            <div className="d-inline-block" id="no_email_send_message_tooltip" aria-disabled="true" aria-hidden="true">
                                <a href="#" className="btn-wide btn btn-primary disabled" disabled>
                                    <i className="icon icon-email-white" style={{margin: "-3px 10px -3px 0"}} /> Send Email
                                </a>
                            </div>
                        </div>
                    )}
                </div>
            )
        } else {

            let empty_message = this.state.message.trim().length === 0;

            return (
                <div className="reply-box">
                    <h4>Send Email</h4>
                    {(this.state.error_msg &&
                        <p className="text-danger">{this.state.error_msg}</p>
                    )}
                    <FormGroup>
                        <Input type="textarea" name="text" value={this.state.message} onChange={this.changeMessage} rows={4}/>
                    </FormGroup>

                    <Row>
                        <Col sm="5">
                            <FileInput onChange={this.changeFiles} files={this.state.files} fileSizeTotalLimit={1024 * 1024 * 18}>
                                <Fa icon="paperclip"/> Add Attachment
                            </FileInput>
                        </Col>
                        <Col sm="7" className="text-right">
                            <div className="custom-control custom-checkbox checkbox-danger d-inline-block mr-3">
                                <input type="checkbox" className="custom-control-input"
                                       checked={this.state.critical} onChange={this.changeCritical}
                                       id="id_critical"/>
                                <label className="custom-control-label" htmlFor="id_critical">Critical</label>
                            </div>

                            <Button color="primary" outline onClick={this.cancelNoteOrMessage.bind(this)} disabled={this.state.sending_message}>Cancel</Button>

                            &#160;

                            {this.state.sending_message ? (
                                <Button color="primary" disabled={true}><Fa icon="spinner" spin /> sending...</Button>
                            ) : (
                                this.props.can_add_email ? (
                                    <Button color="primary" onClick={this.addMessage} disabled={empty_message}>Send Email</Button>
                                ) : (
                                    <div className="d-inline-block">
                                        <UncontrolledTooltip target="no_email_send_message_tooltip">
                                            No email address on file
                                        </UncontrolledTooltip>

                                        <div className="d-inline-block" id="no_email_send_message_tooltip" data-toggle="tooltip" aria-disabled="true" aria-hidden="true">
                                            <a href="#" className="btn btn-primary disabled" disabled>Send Email</a>
                                        </div>
                                    </div>
                                )
                            )}
                        </Col>
                    </Row>
                </div>
            )
        }
    }

    renderTableHeader() {
        return (
            <tr>
                <th>Type</th>
                <th>Sender</th>
                <th>Details</th>
                <th>Date/Time</th>
                <th/>
            </tr>
        )
    }

    renderTable() {
        if(this.state.loading) {
            return (
                <div className="text-center mt-4">
                    <span className="text-muted"><Fa icon="spinner" spin /> loading...</span>
                </div>
            );
        }

        if(this.state.messages.length === 0) {
            return (
                <div className="text-center mt-4">
                    <span className="text-muted">No interactions found</span>
                </div>
            );
        }

        return (
            <Table className="interactions-table table-responsive-md">
                <thead>
                    {this.renderTableHeader()}
                </thead>
                <tbody>
                    {this.state.messages.map((message) =>
                        <InteractionRow
                            key={message.id}
                            message={message}
                            reload={this.loadMessages}/>
                    )}
                </tbody>
            </Table>
        )
    }

    renderFooter() {
        return (
            <div className="table-list-footer">
                <Paginator activePage={this.state.current_page} numPages={this.state.total_pages} onSelect={this.changePage} />
            </div>
        );
    }

    render() {
        return (
            <div className={"interactions-list table-list"}>
                <div className="table-list-header">
                    {this.renderHeader()}

                    {this.renderReply()}
                </div>

                {this.renderTable()}

                {this.renderFooter()}

            </div>
        );
    }
}

VendorInteractionsPanel.propTypes = {
    channel: PropTypes.number,
    can_add_email: PropTypes.bool,
};

VendorInteractionsPanel.defaultProps = {
    can_add_email: true,
};
