import React, {Component, Fragment} from 'react';
import { Row, Col, Button } from "reactstrap";
import axios from 'axios';
import {Form, Scope} from 'informed';
import {AddressRow, BootstrapPhoneText, ConditionallyRequiredInput,} from "../util/formUtils";
import {
    validatePasswordConfirmation, validatePasswordStrength,
    validatePercent, validatePhone,
    validateRequired,
    validateRequiredEmail,
    validateSSN
} from '../forms/inputValidators';
import {BootstrapCheckbox, BootstrapText} from "alpaca.js/dist/autoform/bootstrap";
import FormSet from "alpaca.js/dist/autoform/formset";
import CustomInputRadioGroup from "../forms/customInputRadioGroup";
import Fa from "alpaca.js/dist/util/fa";

const companyTypeOptions = [
    {value: "corporation", id:"company_type_corporation", label:"Corporation"},
    {value: "sole_proprietorship", id:"company_type_sole_proprietorship", label:"Sole Proprietorship"},
    {value: "partnership", id:"company_type_partnership", label:"Partnership"},
];

const timeUnits = [
    {value: 'days', id:"unit_days", label:"Days"},
    {value: 'weeks', id:"unit_weeks", label:"Weeks"},
    {value: 'months', id:"unit_months", label:"Months"},
    {value: 'years', id:"unit_years", label:"Years"},
];

export default class VendorSignup extends Component{
    constructor(props){
        super(props);
        this.submitSignup = this.submitSignup.bind(this);
        this.onSubmit = this.onSubmit.bind(this);
        this.renderContactForm = this.renderContactForm.bind(this);
        this.state = {
            other_company_type: "",
        };
    }

    onSubmit(form){
        return event => {
            form.formApi.submitForm().then((success) => {
                if (_.isEmpty(form.formState.errors)){
                    this.submitSignup(form);
                }
            }).catch((error)=>{
                console.log(error);
            });
        }
    }

    submitSignup(form) {
        const controller = form.controller;
        axios.post(`/api/vendors-signup/`, form.formState.values).then((response) => {
            if (response.status === 201){
                window.location.pathname = response.data.next_url;
            }   // should always be 201 or be handled in catch
            else {
                let form = controller.getFormState();
                form.errors = error.response.data;
                controller.setFormState(form);
            }
        }).catch((error) => {
            let form = controller.getFormState();
            form.errors = error.response.data;
            controller.setFormState(form);
        });
    }

    renderContactForm(child_index, delete_function){
        return (
            <Fragment key={child_index}>
                <Row>
                    <Col sm={10}>
                        <BootstrapText
                            field="name"
                            label="Additional Owner Name"
                            validate={validateRequired}
                        />
                    </Col>
                    <Col sm={1} className="form-group delete-wrapper">
                        <label className="d-block">&nbsp;</label>
                        <button type="button" className="btn btn-danger btn-sm" onClick={delete_function}>
                            <Fa icon="times" className="mr-0 ml-0"/>
                        </button>
                    </Col>
                </Row>
            </Fragment>
        )
    }

    render(){
        let {sectionCount, currSection, form}  = this.props;
        return(
            <div className="container vendor-info-form">
                <Form>
                    {( form ) => (
                        <Fragment>

                            {/*<pre>{ JSON.stringify(form.formState.values) }</pre>*/}
                            {/*<pre>{ JSON.stringify(form.formState.errors) }</pre>*/}
                            <Row>
                                <Col className="col-sm-12">
                                <BootstrapText
                                    label="Full Name of Company"
                                    field="name"
                                    validate={validateRequired}
                                />
                                </Col>
                            </Row>

                            <h2>Address</h2>

                            <AddressRow showLabels address_label="Physical Address" scope='physical_address'/>
                            <Row>
                                <Col className="col-sm-12">
                                <BootstrapCheckbox
                                    label="Mailing address same as physical address"
                                    field="mailing_equal_physical"
                                    validate={value => {return undefined;}}
                                    notify={[
                                        'mailing_address.line1', 'mailing_address.city', 'mailing_address.zip_code',
                                        'physical_address.line1', 'physical_address.city', 'physical_address.zip_code'
                                    ]}

                                />
                                </Col>
                            </Row>
                            <AddressRow showLabels address_label="Mailing Address (Remit To)" scope='mailing_address' isMailingAddr={true}/>

                            <hr class="mt-5 mb-5"/>

                            <h2>Contacts</h2>
                            <Row>
                                <Col className='col-sm-6'>
                                    <BootstrapText
                                        label="Designated Direct Contact Name"
                                        field="contact_name"
                                        validate={validateRequired}

                                    />
                                </Col>
                                <Col className='col-sm-6'>
                                    <BootstrapText
                                        label="Company Owner Name 1"
                                        field="owner"
                                        validate={validateRequired}

                                    />
                                </Col>
                            </Row>
                            <FormSet field="additional_owners" className="formset" children={this.renderContactForm} footerTemplate={(onAdd) => (
                                    <button onClick={onAdd} type="button" className="btn btn-outline-primary btn-wide-2">
                                        <Fa icon="plus" /> Add Company Owner Name
                                    </button>
                            )}>
                            </FormSet>

                            <hr class="mt-5 mb-5"/>

                            <Row>
                                <BootstrapPhoneText label="Primary Phone Number" field="primary_phone"/>
                                <Col sm={6}>
                                    <BootstrapText label="Fax Number" field="fax" placeholder="+0 000 000 0000" validate={validatePhone} />
                                </Col>
                            </Row>
                            <Row>
                                <BootstrapPhoneText label="Alternate Contact Number 1" field="alt_phone1" required={false}/>
                                <BootstrapPhoneText label="Alternate Contact Number 2" field="alt_phone2" required={false}/>
                            </Row>
                            <Row>
                                <Col sm={6}>
                                    <BootstrapText label="Email" field="email" validate={validateRequiredEmail} />
                                </Col>
                            </Row>

                            <hr className="mt-5 mb-5"/>

                            <CustomInputRadioGroup
                                label="Is your Company:"
                                field="company_type"
                                notify={['ssn']}
                                options={companyTypeOptions}
                                radioInline={true}
                                other={true}
                                otherInput={{placeholder: 'Please provide your company ownership type'}}
                                form={form}
                                other_value={this.state.other_company_type}
                                other_id="company_type_other"
                            />

                            <Row>
                                <Col sm={12}>
                                    <ConditionallyRequiredInput
                                        label="If Sole Proprietorship please provide Social Security Number"
                                        field="ssn"
                                        placeholder="000-00-0000"
                                        conditionField="company_type"
                                        conditionValue="sole_proprietorship"
                                        extraValidation={validateSSN}
                                        inline={true}
                                    />
                                </Col>
                            </Row>

                            <hr className="mt-5 mb-5"/>

                            <Row>
                                <Col sm={6}>
                                    <CustomInputRadioGroup
                                        label={"Is your Company required to pay State Sales Tax?"}
                                        field="sales_tax"
                                        notify={["sales_tax_percentage"]}
                                        form={form}
                                        radioInline={true}
                                    />
                                </Col>
                                <Col sm={6}>
                                    <ConditionallyRequiredInput
                                        type="number"
                                        label="If yes, what is the %?"
                                        field="sales_tax_percentage"
                                        className="form-control ml-3"
                                        conditionField={"sales_tax"}
                                        conditionValue={true}
                                        extraValidation={validatePercent}
                                        placeholder={6}
                                    />
                                </Col>
                            </Row>

                            <hr className="mt-5 mb-5"/>

                            <Row>
                                <Col sm={6}>
                                    <CustomInputRadioGroup
                                        label={"Are you licensed?"}
                                        field="licensed"
                                        notify={["license_num"]}
                                        form={form}
                                        radioInline={true}
                                    />
                                </Col>
                                <Col sm={6}>
                                    <ConditionallyRequiredInput
                                        label="If yes, what is your license number?"
                                        field="license_num"
                                        conditionField={"licensed"}
                                        conditionValue={true}
                                        inline={false}
                                    />
                                </Col>
                            </Row>

                            <hr className="mt-5 mb-5"/>

                            <Row>
                                <Col sm={6}>
                                    <BootstrapText
                                        label="How many employees do you have?"
                                        field="employee_count"
                                        type="number"
                                        validate={validateRequired} />
                                </Col>
                                <Col sm={6}>
                                    <ConditionallyRequiredInput
                                        type="radio"
                                        label={"Are they certified?"}
                                        field="employees_certified"
                                        containerClassName="pl-0"
                                        form={form}
                                        radioInline={true}
                                    />
                                </Col>
                            </Row>

                            <hr className="mt-5 mb-5"/>

                            <BootstrapText label="Please list current certifications" field="certifications"/>

                            <hr className="mt-5 mb-5"/>

                            <CustomInputRadioGroup
                                form={form}
                                label={"Does your Company require uniforms to be worn?"}
                                field="uniforms_required"
                                radioInline={true}
                                horizontal={true} />

                            <CustomInputRadioGroup
                                form={form}
                                label={"Does Your Company utilize marked vehicles?"}
                                field="marked_vehicles"
                                radioInline={true}
                                horizontal={true} />

                            <CustomInputRadioGroup
                                form={form}
                                label={"Does your Company utilize a digital camera?"}
                                field="camera"
                                radioInline={true}
                                horizontal={true} />

                            <CustomInputRadioGroup
                                form={form}
                                label={"Does your Company sub-contract other service technicians?"}
                                field="sub_contract"
                                radioInline={true}
                                horizontal={true}
                                notify={["sub_contractor"]} />

                            <Row>
                                <Col sm={6}/>
                                <Col sm={6}>
                                    <ConditionallyRequiredInput
                                        label="If yes, who?"
                                        field="sub_contractor"
                                        conditionField="sub_contract"
                                        conditionValue={true}
                                        horizontal={true} />
                                </Col>
                            </Row>

                            <CustomInputRadioGroup
                                form={form}
                                label={"Does your Company carry Liability Insurance to cover 3rd-party service technicians?"}
                                className="mt-3"
                                field="insurance"
                                radioInline={true}
                                horizontal={true}
                                notify={["insurance_name"]} />

                            <Row>
                                <Col sm={6}/>
                                <Col sm={6}>
                                    <ConditionallyRequiredInput
                                        className="form-control ml-3"
                                        label={"If yes, who?"}
                                        field="insurance_name"
                                        conditionField="insurance"
                                        conditionValue={true}/>
                                </Col>
                            </Row>

                            <Row className="mt-3">
                                <Col sm={6}>
                                    <BootstrapText
                                        label="How long has your company been in business?"
                                        field="company_lifetime_num"
                                        type="number"
                                        validate={validateRequired} />
                                </Col>
                                <Col sm={6} className="mt-3 pt-1">
                                    <CustomInputRadioGroup
                                        form={form}
                                        type="radio"
                                        field="company_lifetime_unit"
                                        options={timeUnits}
                                        radioInline={true} />
                                </Col>
                            </Row>

                            <hr className="mt-5 mb-5"/>

                            <h2 className="mb-3">Primary User Account</h2>
                            <Scope scope="user">
                                <Row>
                                    <Col className={"col-sm-6"}>
                                        <BootstrapText label="Name" field="name"/>
                                    </Col>
                                    <Col className={"col-sm-6"}>
                                        <BootstrapText label="Email" field="email" validate={validateRequiredEmail}/>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col className={"col-sm-6"}>
                                        <BootstrapText label="Password" field="password1" type="password" validate={validatePasswordStrength}/>
                                    </Col>
                                    <Col className={"col-sm-6"}>
                                        <BootstrapText label="Confirm Password" field="password2" type="password" validate={validatePasswordConfirmation}/>
                                    </Col>
                                </Row>
                            </Scope>


                            <Row>
                                <Col className="col-sm-12 text-center">
                                    <Button color="primary" className="mt-4" onClick={this.onSubmit(form)}>
                                        Next <Fa icon="arrow-right" className="pl-3"/>&#160;
                                    </Button>
                                </Col>
                            </Row>
                            {!_.isEmpty(form.formState.errors) &&
                                <Row className="mt-4">
                                    <Col className="text-center">
                                        <p class="invalid-feedback text-center auto-width ">
                                            There are errors and the form cannot be submitted yet. Check fields for error messages to fix the issues.
                                        </p>
                                    </Col>
                                </Row>
                            }
                        </Fragment>
                    )}
                </Form>
            </div>
        )
    }
}
