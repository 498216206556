import React, {Fragment} from 'react';
import PropTypes from 'prop-types';
import { getUrlFileName } from 'alpaca.js/dist/util/util';
import {crmClient} from "../crmapi";
import Fa from "alpaca.js/dist/util/fa";


export default class AttachmentLink extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            loading: false,
        }
    }

    openAttachment(e) {
        e.preventDefault();

        let attachment = this.props.attachment;

        this.setState({loading: true});
        let component = this;

        crmClient.get(`messages/${this.props.messageId}/view_attachment/?attachment=${attachment.id}`).then(function (response) {
            component.setState({loading: false});

            let win = window.open(response.data.url, '_blank');
            win.focus();
        });

        return false;
    }

    render() {
        let attachment = this.props.attachment;

        return (
            <Fragment>
                {this.state.loading ? (
                    <Fa icon="spinner" spin/>
                ) : (
                    <Fa icon="paperclip"/>
                )}
                &#160;
                <button className="btn btn-link p-0" style={{fontWeight: "normal"}} onClick={this.openAttachment.bind(this)} disabled={this.state.loading}>{getUrlFileName(attachment.attachment)}</button>
                &#160;
            </Fragment>
        );
    }
}

AttachmentLink.propTypes = {
    messageId: PropTypes.number.isRequired,
    attachment: PropTypes.object.isRequired
};
