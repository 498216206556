import React, {Component, Fragment, useState} from 'react';
import { Radio, RadioGroup, withRadioGroup, withFormState, useFieldState} from 'informed';
import {validateRequired} from "../forms/inputValidators";
import classNames from "classnames";


const OtherInput = ({radioGroupApi, ...props}) => {
    const inputProps = {
        type: 'text',
        className: 'form-control',
        onChange: (e)=>{radioGroupApi.setValue(e.target.value)},
        ...props
    };
    return <input {...inputProps}/>;
};


const RadioItem = ({radioInline, value, disabled, label, field}) => {
    const [ id ] = useState(_.uniqueId(`${field}-`));

    return (
        <div key={id} className={classNames("custom-control", "custom-radio", {"custom-control-inline": radioInline})}>
            <Radio className='custom-control-input' value={value} id={id} disabled={disabled}/>
            <label className='custom-control-label' htmlFor={id}>{label}</label>
        </div>
    )
};


const OtherRadio = withRadioGroup(({
      radioGroupState, radioGroupApi, // from RadioGroupContext
      other, options, form, field, otherInput, radioInline=false // from form config
    }) => {
    const value = _.get(form, ['formState', 'values', field]);
    const nonOtherValues = _.map(options, "value");
    const isOther = (!_.isUndefined(value) && !_.includes(nonOtherValues, value));
    const showOtherInput = otherInput !== false;
    const defaultValue = showOtherInput ? '' : 'Other';

    const radioProps = {
        id: `${field}-other`,
        checked: isOther,
        onChange: (e)=>{radioGroupApi.setValue(defaultValue)},
        className: "custom-control-input",
        type: "radio",
        value: "other",
    };

    return <div className={classNames("custom-control", "custom-radio", radioInline ? "d-flex-inline" : "d-flex-inline", "align-items-center", {"custom-control-inline": radioInline})}>
        <input {...radioProps} />
        <label className="custom-control-label pr-3" htmlFor={`${field}-other`}>Other</label>
        {showOtherInput && isOther && <OtherInput {...{value, radioGroupApi, ...otherInput}} />}
    </div>
});


const CustomInputRadioGroupComponent = props => {
    let {options, field, other, notify, className, inline, label, validateOnChange, onChange, horizontal } = props;
    const otherProps = _.pick(props, ['otherInput', 'options', 'form', 'field', 'radioInline']);
    const fieldState = useFieldState(field);
    let error = fieldState.error;

    return (
        <div className={classNames("form-group", {"is-invalid": error, "row": horizontal})}>
            {label && <label className={classNames({"col": horizontal, "mr-2": inline})}>{label}</label>}

            <div className={classNames({"col": horizontal}, className)}>
                <RadioGroup
                    field={field}
                    notify={notify}
                    validate={validateRequired}
                    validateOnChange={validateOnChange}
                    onChange={onChange}
                >
                    {options.map(({value, label}, index) => (
                        <RadioItem
                            key={`${field}-${label}-${index}`}
                            radioInline={props.radioInline}
                            value={value}
                            disabled={props.disabled}
                            label={label}
                            field={props.field} />
                    ))}

                    {other && <OtherRadio {...otherProps} />}
                </RadioGroup>

                {(error) &&
                    <div className="invalid-feedback">{error}</div>
                }
            </div>
        </div>
    );
};


const CustomInputRadioGroup = withFormState(CustomInputRadioGroupComponent);
export default  CustomInputRadioGroup

CustomInputRadioGroup.defaultProps = {
    label: "",
    options: [
        {value: true, label: "Yes"},    // if id=null, will make id = {field}_{value}
        {value: false, label: "No"}
    ],
    field: "yes_no",
    other: false,          // set to True if you want to have an 'other' option to be added
    form: null,            // only needed if other is set
    other_value: null,     // set if you don't want the form value to be 'other' i.e. this.state.other_company_type
    other_id: null,        // set if you have more than one in form and it would be an issue for the id to be 'other'
    otherInput: false,     // Properties to pass to DefaultOtherInput, if false, DefaultOtherInput will not show.
    inline: false,         // layout the form label as a form-inline
    radioInline: false,    // layout the radio inputs inline
    horizontal: false,
};
